import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import Home from "../views/dashboards/home/Home.vue";
import Login from "../views/auth/Login.vue";
import Logout from "../views/auth/Logout.vue";
import Tools from "../views/dashboards/flex-tools/Tools.vue";
import EntitiesDashboard from "../views/dashboards/entities/EntitiesDashboard.vue";
import SubscriberDashboard from "../views/dashboards/entities/SubscriberDashboard.vue";
import AllProjectsDashboard from "../views/dashboards/projects/AllProjects.vue";
import ShowProjectDashboard from "../views/dashboards/projects/ShowProject.vue";
import CreateProjectDashboard from "../views/dashboards/projects/CreateProject.vue";
import PageNotFound from "../views/http-errors/PageNotFound.vue";
import useAccount from "../services/account";
import projectEditComponent from "../views/components/dashboards/project/projectEditComponent.vue";
import projectOverviewComponent from "../views/components/dashboards/project/projectOverviewComponent.vue";
import projectMapComponent from "../views/components/dashboards/project/projectMapComponent.vue";
import projectGridComponent from "../views/components/dashboards/project/projectGridComponent.vue";
import SubstationDashboard from "../views/dashboards/entities/SubstationDashboard.vue";
import SubstationOverviewComponent from "../views/components/dashboards/substation/SubstationOverviewComponent.vue";
import SubstationMapComponent from "../views/components/dashboards/substation/SubstationMapComponent.vue";
import SubstationGridComponent from "../views/components/dashboards/substation/SubstationGridComponent.vue";
import TransformerComponent from "../views/components/dashboards/transformer/TransformerComponent.vue";
import TrafoDashboard from "../views/dashboards/entities/TrafoDashboard.vue";
import SubscriberOverviewComponent from "../views/components/dashboards/subscriber/SubscriberOverviewComponent.vue";
import SubscriberMetadataComponent from "../views/components/dashboards/subscriber/SubscriberMetadataComponent.vue";
import SubstationMetadataComponent from "../views/components/dashboards/substation/SubstationMetadataComponent.vue";
import TransformerMetadataComponent from "../views/components/dashboards/transformer/TransformerMetadataComponent.vue";

const { loggedIn } = useAccount();

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: PageNotFound,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      requiresAuth: true,
      breadcrumb: "Home",
    },
  },
  {
    path: "/dashboards/entities",
    name: "entities-dashboard",
    component: EntitiesDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboards/entities/substation/:objectId",
    name: "substation",
    props: true,
    component: SubstationDashboard,
    redirect: { name: "substation.overview" },
    children: [
      {
        path: "overview",
        name: "substation.overview",
        component: SubstationOverviewComponent,
      },
      {
        path: "map",
        name: "substation.map",
        component: SubstationMapComponent,
      },
      {
        path: "grid",
        name: "substation.grid",
        component: SubstationGridComponent,
      },
      {
        path: "metadata",
        name: "substation.metadata",
        component: SubstationMetadataComponent,
      },
      {
        path: "trafo",
        name: "substation.trafo",
        component: TransformerComponent,
        props: true,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboards/entities/transformer/:objectId",
    name: "transformer-dashboard",
    props: true,
    component: TrafoDashboard,
    redirect: { name: "trafo.overview" },
    children: [
      {
        path: "overview",
        name: "trafo.overview",
        component: TransformerComponent,
        props: true,
      },
      {
        path: "metadata",
        name: "trafo.metadata",
        component: TransformerMetadataComponent,
        props: true,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboards/entities/subscriber/:objectId",
    name: "subscriber-dashboard",
    props: true,
    component: SubscriberDashboard,
    redirect: { name: "subscriber.overview" },
    children: [
      {
        path: "overview",
        name: "subscriber.overview",
        component: SubscriberOverviewComponent,
      },
      {
        path: "metadata",
        name: "subscriber.metadata",
        component: SubscriberMetadataComponent,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboards/flex-tools/",
    name: "flex-tools-dashboard",
    component: Tools,
    meta: { requiresAuth: true },
  },
  {
    path: "/dashboards/projects",
    name: "all-projects",
    component: AllProjectsDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboards/projects/create",
    name: "create-project",
    component: CreateProjectDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboards/projects/:id",
    name: "project",
    props: true,
    component: ShowProjectDashboard,
    redirect: { name: "project.overview" },
    children: [
      {
        path: "overview",
        name: "project.overview",
        component: projectOverviewComponent,
      },
      { path: "map", name: "project.map", component: projectMapComponent },
      { path: "grid", name: "project.grid", component: projectGridComponent },
      { path: "edit", name: "project.edit", component: projectEditComponent },
    ],
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !loggedIn()) {
    return next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  }
  console.log(router.currentRoute.value);
  return next();
});

export default router;
