
import AzureAuthenticator from '../../services/azure-authentication';
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
    setup() {
        const azure = new AzureAuthenticator();
        const route = useRoute();
        onMounted(async () => {
            let state = route.query.redirect ? route.query.redirect.toString() : '';

            await azure.login(state);
        });
    },
});
