<template>
  <ion-page>
    <ion-content>
      <div class="nn__home section__padding">
        <div class="nn__home-content">
          <h1 class="gradient__text">
            Vi sørger for at strømmen alltid kommer fram
          </h1>
          <p class="nn__home-content__description">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        </div>
      </div>
      <footer-component></footer-component>
    </ion-content>
  </ion-page>
</template>

<script type="ts">
import {
    IonPage,
} from '@ionic/vue';
import {
    defineComponent

} from 'vue';
import FooterComponent from '@/views/components/FooterComponent.vue';

export default defineComponent({
    name: 'Home',
    components: {
        IonPage,
        FooterComponent
    },

});
</script>

<style>
.nn__home {
  display: flex;
}

.nn__home-content {
  font-family: var(--font-primary);
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  margin-right: 5rem;
}

.nn__home-content h1 {
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04rem;
}

.nn__home-content p {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: var(--color-text);

  margin-top: 1.5rem;
}

@media screen and (max-width: 1050px) {
  .nn__home {
    flex-direction: column;
  }

  .nn__home-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .nn__home h1 {
    font-size: 45px;
    line-height: 60px;
  }

  .nn__home p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .nn__home h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .nn__home p {
    font-size: 14px;
    line-height: 24px;
  }
}
</style>
