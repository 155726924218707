
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";
import BannerComponent from "./views/components/BannerComponent.vue";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    // BreadCrumb,
    BannerComponent,
  },
  data() {
    let crumbs: string[] = [];

    return { crumbs };
  },
  methods: {
    selected(crumb: any) {
      console.log(crumb);
    },
  },
  // watch: {
  //   $route(to: NavigationItem, from: NavigationItem) {
  //     // empty nav items if home is selcted
  //     if (to.path === "/home") {
  //       this.crumbs = [];
  //       return;
  //     }
  //     this.crumbs.push(to.name);
  //   },
  // },
});
