import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_grafana_component = _resolveComponent("grafana-component")!
  const _component_grafana_timeframe_component = _resolveComponent("grafana-timeframe-component")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { style: {"height":"100%","display":"flex","flex-flow":"column"} }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { style: {"flex-grow":"1"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_grafana_timeframe_component, null, {
                        default: _withCtx(({ timeFrame }) => [
                          (_ctx.getSubscriber.meterNumber)
                            ? (_openBlock(), _createBlock(_component_grafana_component, {
                                key: 0,
                                src: "JJLhjnEMk/dashboard-abonnent",
                                args: [`var-MeterId=${_ctx.getSubscriber.meterNumber}`, timeFrame]
                              }, null, 8, ["args"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}