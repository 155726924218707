import { AxiosInstance } from 'axios';
import HttpAxios from './httpAxios';
import httpAxios from "./httpAxios";
import useLoading from './loading';
export interface ProjectInterface {
    id: number;
    name: string;
    description: string;
    nodesGridNodeId: string;
    nodesMarketId: string;
    metadataFilters?: string;
}

export interface ProjectsInterface extends ProjectInterface {
    node: NodeInterface;
}
export interface NodeInterface {
    objectId: string;
    componentName: string;
    transformerCircuit: string;
    location?: string;
    distSubstationName?: string;
    x: number;
    y: number;
}

export interface FlexProjectFormInterface {
    id: number;
    name: string;
    description: string;
    nodesGridNodeId: string;
    nodesMarketId: string;
    grid: {
        componentName: string;
        transformerCircuit: string;
    };
}
export interface ProjectServiceInterface {
    get(id: number): Promise<ProjectInterface>;
    getAll(): Promise<ProjectsInterface[]>;
    create(project: ProjectInterface): Promise<ProjectInterface>;
    update(project: ProjectInterface): Promise<ProjectInterface>;
    delete(id: number): Promise<void>;
}

const { setLoadingStatus } = useLoading();
export class ProjectService implements ProjectServiceInterface {
    httpAxios: httpAxios;
    httpClient: AxiosInstance;
    API_KEY: string;

    constructor(private a: HttpAxios) {
        this.httpAxios = a;
        this.httpClient = this.httpAxios.getClient("flex");
        this.API_KEY = this.httpAxios.getApiKey("flex");
    }

    async get(id: number): Promise<ProjectInterface> {
        let project: ProjectInterface = {} as ProjectInterface;

        try {
            setLoadingStatus(true);
            project = await (await this.httpClient.get(`/project/${id}?key=${this.API_KEY}`)).data.project;
        } catch (err) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return project;
    }

    async getAll(): Promise<ProjectsInterface[]> {
        let projects: Array<ProjectsInterface> = [];

        try {
            setLoadingStatus(true);
            projects = await (await this.httpClient.get(`/projects?key=${this.API_KEY}`)).data.projects;
        } catch (err) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return projects;
    }

    async create(project: ProjectInterface): Promise<ProjectInterface> {
        let newProject: ProjectInterface;

        try {
            setLoadingStatus(true);
            newProject = (await this.httpClient.post(`/project?key=${this.API_KEY}`, { project: project })).data.project;
        } catch (err) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return newProject;
    }

    async update(project: ProjectInterface): Promise<ProjectInterface> {
        let updatedProject: ProjectInterface;

        try {
            setLoadingStatus(true);
            updatedProject = (await this.httpClient.put(`/project/${project.id}?key=${this.API_KEY}`, { project: project })).data.project;
        } catch (err) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return updatedProject;
    }

    async delete(id: number): Promise<void> {
        try {
            setLoadingStatus(true);
            await this.httpClient.delete(`/project/${id}?key=${this.API_KEY}`)
        } catch (err) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return;
    }
}
