import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_google_map_component = _resolveComponent("google-map-component")!
  const _component_router_wrapper_component = _resolveComponent("router-wrapper-component")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { style: {"height":"100%","display":"flex","flex-flow":"column"} }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { style: {"flex-grow":"1"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, { style: {"flex-grow":"1"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_router_wrapper_component, null, {
                        default: _withCtx(({ pushRoute }) => [
                          _createVNode(_component_google_map_component, {
                            class: "iframe",
                            id: "map",
                            dataSet: _ctx.mapData,
                            markers: true,
                            selectable: true,
                            markSelected: false,
                            onMarkerSelected: ($event: any) => (pushRoute($event.objectId, $event.componentName))
                          }, null, 8, ["dataSet", "onMarkerSelected"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}