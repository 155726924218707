
import { defineComponent, onMounted, ref, computed } from 'vue';
import { IonCard, IonCardContent, IonText, IonToggle, IonLabel, IonItem, IonButton, IonIcon } from '@ionic/vue';
import { trashOutline, createOutline } from 'ionicons/icons';
import useState from '@/services/state';
import useServices from '@/services/services';
import { BuyOrderInterface } from '@/services/nodes';
import eventBus from '@/services/eventBus';
import BuyOrderFormComponent from './BuyOrderFormComponent.vue';
import Helpers from '@/services/helpers'

const { getProject } = useState();
const { nodesService } = useServices();
const showInactive = ref(false);
const buyOrders = ref<Array<BuyOrderInterface>>([]);

async function fetch() {
    buyOrders.value = await nodesService.getBuyOrders(getProject.value.nodesGridNodeId, getProject.value.nodesMarketId);
}

async function deleteBuyOrder(id: string): Promise<void> {
    try {
        await nodesService.deleteBuyOrder(id);

        eventBus().emitter.emit('update');
        eventBus().emitter.emit('updateOrderBook');
    } catch (err) {
        console.log(err);
    }
}

export default defineComponent({
    name: 'ListAllOrdersComponent',
    components: {
        IonCard,
        IonCardContent,
        IonText,
        IonToggle,
        IonLabel,
        IonItem,
        IonButton,
        IonIcon,
        BuyOrderFormComponent,
    },
    setup(): object {
        const filteredBuyOrders = computed(() => {
            if (showInactive.value) {
                return buyOrders.value;
            }

            return buyOrders.value.filter((order) => {
                return order.status == ('Active' || 'Pending' || 'Received');
            });
        });
        const edit = ref('');

        onMounted(async () => {
            fetch();

            eventBus().emitter.on('update', () => fetch());
        });

        return {
            Helpers,
            filteredBuyOrders,
            deleteBuyOrder,
            getProject,
            showInactive,
            trashOutline,
            createOutline,
            edit,
        };
    },
});
