import { MapItemInterface, Markers } from "@/models/map";
import { GridInterface } from "@/services/grid";
import Helpers from "@/services/helpers";
import { NodeInterface, ProjectsInterface } from "@/services/project";

export class MapItemHelper {
    static projectToMapItem(projects: ProjectsInterface[]): MapItemInterface[] {
        return projects.map((project) => ({
            id: project.id,
            type: 'project',
            enabled: true,
            labelText: project.name,
            icon: project.node.x ? Markers.SELECTED : Markers.MISSING,
            x: project.node.x ? project.node.x : 5.198408 + Math.random() / 100,
            y: project.node.y ? project.node.y : 59.448050 + Math.random() / 100
        }) as MapItemInterface);
    }

    static nodeToMapItem(nodes: NodeInterface[]): MapItemInterface[] {
        let data: Array<MapItemInterface> = [];

        nodes.forEach((node) => {
            if (this.isTransformer(node) && !node.x) {
                nodes.some((n) => {
                    if (n.componentName === 'NETTSTASJON' && (n.transformerCircuit === node.transformerCircuit)) {
                        node.x = n.x;
                        node.y = n.y;
                        return true;
                    }
                    return false;
                });
            }

            let mapItem = {
                id: parseInt(node.objectId),
                objectId: parseInt(node.objectId),
                componentName: node.componentName,
                enabled: true,
                type: 'node',
                labelText: `${node.componentName}: ${node.transformerCircuit}`,
                icon: node.x ? Object(Markers)[node.componentName] : Markers.MISSING, //Unable to use node.transformerCircuit for Markers indexing
                x: node.x ? node.x : 5.198408 + Math.random() / 100,
                y: node.y ? node.y : 59.448050 + Math.random() / 100,
            }

            data.push(mapItem);
        });

        return data;
    }

    static gridToMapItem(grid: GridInterface[] | undefined, parent: GridInterface | null = null): MapItemInterface[] {
        let data: Array<MapItemInterface> = [];
        if (!grid) return data;

        grid.forEach((item) => {
            if (parent && item.componentName != 'ABONNENT') {
                if (!item.x) {
                    item.x = parent.x
                    item.y = parent.y
                }
            }

            let labelText = item.componentName == 'ABONNENT' ? `${item.componentName.charAt(0)}: ${item.meterId}` : `${item.componentName}: ${item.transformerCircuit}`;

            let voltageOutOfRange = Helpers.voltageOutOfRange(item.predictedVoltages?.min);
            if(!voltageOutOfRange) voltageOutOfRange = Helpers.voltageOutOfRange(item.predictedVoltages?.max);

            let mapItem = {
                id: item.flexId,
                parentId: item.parentId,
                objectId: item.objectId,
                type: 'grid',
                componentName: item.componentName,
                enabled: this.mapEnabled(item),
                voltageOutOfRange: voltageOutOfRange,
                labelText: labelText,
                icon: item.x ? Object(Markers)[item.componentName] : Markers.MISSING,
                x: item.x ? item.x : 5.198408 + Math.random() / 100,
                y: item.y ? item.y : 59.448050 + Math.random() / 100,
                children: this.gridToMapItem(item.children, item)
            }


            data.push(mapItem);
        });

        return data;
    }

    static mapEnabled(item: GridInterface): boolean {
        if (item.componentName === 'UTGANGOGKURS') {
            return false;
        }

        return true;
    }

    static isTransformer(node: NodeInterface | GridInterface): boolean {
        let transformerTypes = [
            'FORDELINGSTRANSFORMATOR',
            '3-VIKLINGS FORDELINGTRAFO',
            'TOVIKLINGSTRANSFORMATOR',
            'TREVIKLINGSTRANSFOMATOR'
        ];

        if (transformerTypes.includes(node.componentName)) return true;

        return false;
    }

    static shouldBeHidden(node: GridInterface): boolean {
        let transformerTypes = [
            'UTGANGOGKURS',
        ];

        if (transformerTypes.includes(node.componentName)) return true;

        return false;
    }
}
