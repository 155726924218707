
import { defineComponent, ref, onMounted } from 'vue';
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/vue';
import useServices from '@/services/services';
import { useRoute } from 'vue-router';
import MetaDataComponent from '../../MetaDataComponent.vue';
import { SubstationInterface } from '@/services/netbasMeta';

export default defineComponent({
    name: 'SubstationMetadataComponent',
    components: {
        IonContent,
        IonPage,
        IonGrid,
        IonRow,
        IonCol,
        MetaDataComponent,
    },
    setup(): object {
        const { netbasMetaService } = useServices();
        const route = useRoute();
        const substationMetadata = ref<SubstationInterface | undefined>(undefined);

        onMounted(async () => {
            substationMetadata.value = await netbasMetaService.getSubstation(
                parseInt(route.params.objectId.toString())
            );
        });

        return { substationMetadata };
    },
});
