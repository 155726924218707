
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from "@ionic/vue";
import { searchOutline } from "ionicons/icons";
import { defineComponent } from "vue";
import BannerComponent from "../../components/BannerComponent.vue";
import RouterWrapperComponent from "../../components/RouterWrapperComponent.vue";
import SearchComponent from "../../components/SearchComponent.vue";

export default defineComponent({
  name: "EntitiesDashboard",
  components: {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    RouterWrapperComponent,
    SearchComponent,
  },
  setup(): object {
    return {
      searchOutline,
    };
  },
});
