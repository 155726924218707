import HttpAxios from './httpAxios';
import { NetbasMetaService, NetbasMetaServiceInterface } from './netbasMeta';
import { ProjectService } from './project';
import { GraphService, GraphsServiceInterface } from './graphs';
import { ProjectServiceInterface } from './project';
import { GridService, GridServiceInterface } from './grid';
import { NodesService, NodesServiceInterface } from './nodes';
import { MetadataService, MetadataServiceInterface } from './metadata';

const client = new HttpAxios();
interface ServicesInterface {
    projectService: ProjectServiceInterface;
    gridService: GridServiceInterface;
    nodesService: NodesServiceInterface;
    netbasMetaService: NetbasMetaServiceInterface;
    metadataService: MetadataServiceInterface;
    graphService: GraphsServiceInterface;
}

const services: ServicesInterface = {
    projectService: new ProjectService(client),
    gridService: new GridService(client),
    nodesService: new NodesService(client),
    netbasMetaService: new NetbasMetaService(client),
    metadataService: new MetadataService(client),
    graphService: new GraphService(client)
};

export default function useServices() {
    return {...services }
}
