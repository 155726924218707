<template>
  <ion-page>
    <!-- <banner-component>
            <div style="font-size: 3rem">Flex Tools</div>
        </banner-component> -->
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Prediction tool PV production</IonCardTitle>
                <IonCardSubtitle>Card Subtitle</IonCardSubtitle>
              </IonCardHeader>

              <IonCardContent>
                Keep close to Nature's heart... and break clear away, once in
                awhile, and climb a mountain or spend a week in the woods. Wash
                your spirit clean.
              </IonCardContent>
            </IonCard>
          </ion-col>
          <ion-col>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Prediction tool wind production</IonCardTitle>
                <IonCardSubtitle>Card Subtitle</IonCardSubtitle>
              </IonCardHeader>

              <IonCardContent>
                Keep close to Nature's heart... and break clear away, once in
                awhile, and climb a mountain or spend a week in the woods. Wash
                your spirit clean.
              </IonCardContent>
            </IonCard>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <IonCard>
              <IonCardHeader>
                <IonCardSubtitle>Card Subtitle</IonCardSubtitle>
                <IonCardTitle>Flexibility tool load</IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
                Keep close to Nature's heart... and break clear away, once in
                awhile, and climb a mountain or spend a week in the woods. Wash
                your spirit clean.
              </IonCardContent>
            </IonCard>
          </ion-col>
          <ion-col>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Prediction tool hydro power</IonCardTitle>
                <IonCardSubtitle>Card Subtitle</IonCardSubtitle>
              </IonCardHeader>

              <IonCardContent>
                Keep close to Nature's heart... and break clear away, once in
                awhile, and climb a mountain or spend a week in the woods. Wash
                your spirit clean.
              </IonCardContent>
            </IonCard>
          </ion-col>
          <ion-col>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle
                  >Prediction tool battery energy level and
                  flexcapasity</IonCardTitle
                >
                <IonCardSubtitle>Card Subtitle</IonCardSubtitle>
              </IonCardHeader>

              <IonCardContent>
                Keep close to Nature's heart... and break clear away, once in
                awhile, and climb a mountain or spend a week in the woods. Wash
                your spirit clean.
              </IonCardContent>
            </IonCard>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script type="ts">
import {
    IonCol,
    IonGrid,
    IonRow,
    IonContent,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
} from '@ionic/vue';
import {
    defineComponent
} from 'vue';
import BannerComponent from '@/views/components/BannerComponent.vue';

export default defineComponent({
    name: 'Tools',
    components: {
        IonCol,
        IonGrid,
        IonRow,
        IonContent,
        IonPage,
        IonCard,
        IonCardHeader,
        IonCardSubtitle,
        IonCardTitle,
        IonCardContent,
    },
    setup() {
        return {};
    }
});
</script>

<style scoped>
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
