
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import useState from '@/services/state';
import TreeChart from '@/views/components/grid/TreeChart.vue';
import RouterWrapperComponent from '@/views/components/RouterWrapperComponent.vue';

export default defineComponent({
    name: 'SubstationGridComponent',
    components: {
        RouterWrapperComponent,
        IonContent,
        IonPage,
        TreeChart,
    },
    setup(): object {
        const { getGrid } = useState();

        return { getGrid };
    },
});
