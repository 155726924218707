<template>
  <div class="nn__footer-container">
    <div class="nn__footer-container__rightside">
      <div>
        <img :src="footerLogo" />
      </div>
      <div class="nn__footer-container__rightside--social">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="nn__footer-contianer__mentions">
      <p>{{ mention }}</p>
    </div>
  </div>
</template>

<script type="ts">
    import {
        defineComponent,
    } from 'vue';

export default defineComponent({
    name: 'FooterComponent',
    components: {},
    props: {},
    data: () => {
        return {
            footerLogo: "https://fagne.no/wp-content/themes/fagne/images/footer-logo.svg",
            backgroundImg: "https://fagne.imgix.net/wp-content/uploads/2021/12/footer_background-scaled.jpg?auto=format%2Ccompress",
            mention : 'Copyright © 2022 Fagne AS'
        }
    },
    methods: {}
});
</script>

<style>
.nn__footer-container {
  max-height: 12vh;
  padding: 1rem 4rem;
  width: 100vw;
  color: var(--color-text--light);
  background-color: var(--color-background--dark);
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0px;
  position: fixed;
}
.nn__footer-container__rightside {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.nn__footer-container__rightside--social {
  width: 20%;
  display: flex;
  justify-content: space-between;
}
.nn__footer-container__rightside--social div {
  height: 30px;
  width: 30px;
  background-color: var(--color-secondary);
  border-radius: 50%;
}
.nn__footer-contianer__mentions p {
  font-size: 0.5em;
}
</style>
