
import { IonContent, IonPage, IonRouterOutlet } from "@ionic/vue";
import { defineComponent, ref, onMounted } from "vue";
import useState from "../../../services/state";
import useServices from "@/services/services";
import TabComponent from "../../components/TabComponent.vue";
import RouterWrapperComponent from "../../components/RouterWrapperComponent.vue";
import { useRoute } from "vue-router";
import eventBus from "@/services/eventBus";

export default defineComponent({
  name: "FlexDashboard",
  components: {
    IonRouterOutlet,
    IonContent,
    IonPage,
    TabComponent,
    RouterWrapperComponent,
  },

  setup(): object {
    const { getProject, setGrid, setProject } = useState();
    const { projectService, gridService } = useServices();
    const route = useRoute();
    const tabs = ref({
      tabs: [
        { name: "project overview", path: "overview" },
        { name: "map view", path: "map" },
        { name: "grid view", path: "grid" },
        { name: "edit project", path: "edit" },
      ],
    });

    onMounted(async () => {
      let project = await projectService.get(
        parseInt(route.params.id.toString())
      );
      setProject(project);

      let grid = await gridService.get(parseInt(route.params.id.toString()));
      setGrid(grid);

      eventBus().emitter.emit("updateOrderBook");
    });

    return {
      getProject,
      tabs,
    };
  },
});
