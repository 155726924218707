import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_grid, { style: {"height":"100%","display":"flex","flex-flow":"column"} }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabRegistry.keys(), (key) => {
                    return (_openBlock(), _createBlock(_component_ion_button, {
                      key: key,
                      onClick: _ctx.handleTabClick,
                      id: key,
                      color: _ctx.tabRegistry.get(key) ? 'primary' : 'medium',
                      fill: "clear"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(key), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick", "id", "color"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, { style: {"flex-grow":"1"} }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default", { selected: _ctx.tabRegistry })
                ]),
                _: 3
              })
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}