
import { defineComponent, onMounted, ref, watch, toRefs } from 'vue';
import { IonToggle, IonList, IonItem, IonLabel, IonThumbnail, IonSearchbar, IonButton } from '@ionic/vue';
import { GridInterface } from '@/services/grid';
export default defineComponent({
    name: 'Abonnent',
    props: {
        dataSet: {
            type: Object as () => GridInterface,
            required: true,
        },
    },
    emits: ['toggle-enabled', 'view'],
    methods: {
        handleToggle(event: any, flexId: number) {
            event.stopImmediatePropagation();
            event.stopPropagation();
            event.preventDefault();
            this.$emit('toggle-enabled', flexId);
        },
        handleView(child: GridInterface) {
            this.$emit('view', child);
        },
    },
    components: {
        IonToggle,
        IonButton,
        IonList,
        IonItem,
        IonLabel,
        IonThumbnail,
        IonSearchbar,
    },
    setup(props): object {
        let searchText = ref('');
        const { dataSet } = toRefs(props);
        let filteredDataset = ref<GridInterface[]>(dataSet.value.children ? dataSet.value.children : []);

        function filterDataset(searchText: string) {
            let children = dataSet.value.children ? dataSet.value.children : [];
            if (searchText.length >= 2) {
                filteredDataset.value = children.filter((child: GridInterface) => {
                    return (
                        child.transformerCircuit?.toLowerCase()?.includes(searchText.toLowerCase()) ||
                        child.location?.toLowerCase()?.includes(searchText.toLowerCase()) ||
                        child.meterId?.toLowerCase()?.includes(searchText.toLowerCase())
                    );
                });
            } else {
                filteredDataset.value = children;
            }
        }

        onMounted(() => {
            watch([dataSet, searchText], ([newDataset, newSearchText], [oldDataset]) => {
                if (newDataset != oldDataset) return filterDataset('');

                return filterDataset(newSearchText.toString());
            });
        });

        return { searchText, filteredDataset };
    },
});
