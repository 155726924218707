
import { IonPage, IonContent, IonRouterOutlet } from "@ionic/vue";
import { defineComponent, ref, watch } from "vue";
import useState from "../../../services/state";
import useServices from "../../../services/services";
import { MapItemHelper } from "../../../../src/helpers/MapItemHelper";
import RouterWrapperComponent from "../../components/RouterWrapperComponent.vue";
import { GridInterface } from "../../../services/grid";
import BannerComponent from "../../components/BannerComponent.vue";
import { SubstationInterface } from "../../../services/netbasMeta";
import TabComponent from "../../components/TabComponent.vue";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "SubstationDashboard",
  components: {
    RouterWrapperComponent,
    IonPage,
    IonContent,
    TabComponent,
    IonRouterOutlet,
  },

  setup(): object {
    const { gridService, netbasMetaService } = useServices();
    const route = useRoute();
    const dataSet = ref<SubstationInterface>({} as SubstationInterface);
    const { getGrid, setGrid } = useState();
    const trafoRegistry = ref(new Map<string, GridInterface>());
    const tabs = ref({
      tabs: [
        { name: "overview", path: "overview" },
        { name: "map", path: "map" },
        { name: "grid", path: "grid" },
        { name: "metadata", path: "metadata" },
      ],
    });

    async function fetchData(id: number) {
      dataSet.value = await netbasMetaService.getSubstation(id);
    }

    async function fetchGrid() {
      let g = await gridService.post(
        -1,
        dataSet.value.componentName,
        dataSet.value.transformerCircuit
      );

      g.children?.forEach((child) => {
        trafoRegistry.value.set(`Trafo-${child.transformerCircuit}`, child);
        tabs.value.tabs.push({
          name: `Trafo-${child.transformerCircuit}`,
          path: `trafo?objectId=${child.objectId}`,
        });
      });

      setGrid(g);
    }

    watch(
      () => route.params,
      async () => {
        await fetchData(parseInt(route.params.objectId.toString()));
        await fetchGrid();
      },
      { immediate: true }
    );

    return {
      getGrid,
      MapItemHelper,
      trafoRegistry,
      dataSet,
      tabs,
    };
  },
});
