import { AxiosInstance } from "axios";
import { GridInterface } from "./grid";
import HttpAxios from "./httpAxios";
import { NodeInterface } from "./project";
import useLoading from "./loading";
import Helpers from './helpers';
export interface SubscriberInterface {
    objectId: number;
    componentName?: string;
    inspectionsetId?: number;
    parentComponentId?: number;
    meterStatus?: string;
    geoMethod?: number;
    installationNo?: string;
    meterNumber: string;
    address?: string;
    postalAddress?: string;
    billingAddress?: string;
    billingZipCode?: string;
    dateMeterConn?: number;
    voltage?: number;
    phase?: string;
    fuseSize?: string;
    tariff?: string;
    endUserGroup?: string;
    localAuthority?: string;
    zone?: string;
    Station?: string;
    Feeder?: string;
    transformerCircuit: string;
    ChangedDate?: number;
    ChangedBy?: string;
    Remark?: string;
    measureMID?: string;
    serviceNumber?: string;
    energyConsumption?: number;
    yearOfReference?: number;
    MinShortCCurrent?: number;
    maxShortCCurrent?: number;
    mimActivePower?: number;
    shaseVoltage?: number;
    voltageDrop?: number;
    x?: number;
    y?: number;
    parents?: Array<object>;
}

export interface SubstationInterface {
    objectId: number;
    componentName: string;
    inspectionsetId?: number;
    serviceNumber?: string;
    voltage?: number;
    location?: string;
    typeDesignation?: string;
    distSubstationName?: string;
    componentAddress?: string;
    substationType?: string;
    manufacture?: string;
    dateOfMounting?: number;
    yearOfConstruction?: number;
    yearOfManufacture?: number;
    numberOfOperatingFeeders?: number;
    numberOfTransformersInstalled?: number;
    earthFaultMonitoring?: string;
    protectedInstallation?: string;
    globalEarth?: string;
    access?: string;
    key?: string;
    owner?: string;
    localAuthority?: number;
    zone?: number;
    mStation?: number;
    mFeeder?: number;
    transformerCircuit: string;
    x?: number;
    Y?: number;
}

export enum NetbasEntities {
    "ABONNENT" = "Abonnent",
    "NETTSTASJON" = "Nettstasjon",
    "FORDELINGSTRANSFORMATOR" = "Fordelingstransformator",
    "3-VIKLINGS FORDELINGTRAFO" = "TreviklingsFordelingstrafo",
    "TOVIKLINGSTRANSFORMATOR" = "Toviklingstransformator",
    "TREVIKLINGSTRANSFORMATOR" = "Treviklingstransformator",
    "UTGANGOGKURS" = "UtgangOgKurs"
}

export enum NetbasEntitiesTranslation {
    "NETTSTASJON" = "substation",
    "FORDELINGSTRANSFORMATOR" = "transformer",
    "ABONNENT" = "subscriber",
    "3-VIKLINGS FORDELINGTRAFO" = "transformer",
    "TOVIKLINGSTRANSFORMATOR" = "transformer",
    "TREVIKLINGSTRANSFORMATOR" = "transformer"
}

export interface TransformerInterface {
    objectId: number;
    componentName: NetbasEntities;
    serviceNumber: number;
    voltage?: number;
    location?: number;
    typeDesignation?: number;
    x?: number;
    y?: number;
}

export interface SearchResultInterface {
    objectId: string;
    componentName: string;
    transformerCircuit: string;
    address?: string;
    location?: string;
    meterNumber?: string;
    x: number;
    y: number;
}

export interface MeterVoltageInterface {
    id: string;
    max: number;
    min: number;
}
export interface MeterVoltageFlaggedInterface extends MeterVoltageInterface {
    componentName: string;
    transformerCircuit: string;
    lowVoltage: boolean;
    highVoltage: boolean;
}

export interface NetbasMetaServiceInterface {
    search(searchString: string, components: string): Promise<SearchResultInterface[]>;
    getTopLevelNodes(): Promise<NodeInterface[]>;
    getSubscriber(objectId: number): Promise<SubscriberInterface>;
    getSubstation(objectId: number): Promise<SubstationInterface>;
    getTransformer(objectId: number): Promise<TransformerInterface>;
    getMeterVoltages(grid: GridInterface): Promise<Array<MeterVoltageFlaggedInterface>>;
}

const { setLoadingStatus } = useLoading();

function iterateGrid(matched: Array<MeterVoltageFlaggedInterface>, parent: GridInterface, meters: Array<MeterVoltageInterface>) {

    if (parent.children) parent.children.forEach((child) => {
        iterateGrid(matched, child, meters);
    });

    meters.forEach((meter) => {
        if (meter.id === parent.meterId) {

            matched.push({
                id: meter.id,
                componentName: parent.componentName,
                transformerCircuit: parent.transformerCircuit,
                min: meter.min,
                max: meter.max,
                lowVoltage: Helpers.voltageOutOfRange(meter.min),
                highVoltage: Helpers.voltageOutOfRange(meter.max),
            });
        }
    });

    return matched;
}
export class NetbasMetaService implements NetbasMetaServiceInterface {
    httpAxios: HttpAxios;
    httpClient: AxiosInstance;
    API_KEY: string;

    constructor(private a: HttpAxios) {
        this.httpAxios = a;
        this.httpClient = a.getClient('netbasmeta');
        this.API_KEY = a.getApiKey('netbasmeta');
    }

    async search(searchString: string, components: string): Promise<SearchResultInterface[]> {
        let result: SearchResultInterface[] = [];

        try {
            setLoadingStatus(true);
            result = (await this.httpClient.get(`/bq/netbas/search?search=${searchString}&type=${components}&key=${this.API_KEY}`)).data.components;
        } catch (err) {
            throw new Error('Could not fetch search results');
        } finally {
            setLoadingStatus(false);
        }

        return result;
    }

    async getTopLevelNodes(): Promise<NodeInterface[]> {
        let toplevels: NodeInterface[] = [];

        try {
            setLoadingStatus(true);
            toplevels = (await this.httpClient.get(`/bq/netbas/toplevel?key=${this.API_KEY}`)).data.toplevels;
        } catch (err) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return toplevels;
    }

    async getSubscriber(objectId: number): Promise<SubscriberInterface> {
        let subscriber: SubscriberInterface = {} as SubscriberInterface;

        try {
            setLoadingStatus(true);
            subscriber = (await this.httpClient.get(`/bq/netbas/subscriber/${objectId}?key=${this.API_KEY}`)).data.subscriber;
        } catch (err) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return subscriber;
    }

    async getSubstation(objectId: number): Promise<SubstationInterface> {
        let substation = {} as SubstationInterface;

        try {
            setLoadingStatus(true);
            substation = (await this.httpClient.get(`/bq/netbas/substation/${objectId}?key=${this.API_KEY}`)).data.substation;
        } catch (err) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return substation;
    }

    async getTransformer(objectId: number): Promise<TransformerInterface> {
        let transformer = {} as TransformerInterface;

        try {
            setLoadingStatus(true);
            transformer = (await this.httpClient.get(`/bq/netbas/transformer/${objectId}?key=${this.API_KEY}`)).data.transformer;
        } catch (err) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return transformer;
    }

    async getMeterVoltages(grid: GridInterface): Promise<Array<MeterVoltageFlaggedInterface>> {
        let transformerCircuits = [grid.transformerCircuit];

        let matched: Array<MeterVoltageFlaggedInterface> = [];
        let meters: Array<MeterVoltageInterface> = [];


        for (const circuit of transformerCircuits) {
            let response: any;
            try {
                response = (await this.httpClient.get(`/bq/datamarts/voltage/${circuit}?key=${this.API_KEY}`)).data.voltageValues;

                if (response && Array.isArray(response)) meters = meters.concat(response);
            } catch (err) {
                throw new Error(err);
            }
        }

        return iterateGrid(matched, grid, meters);
    }

}
