
import { IonContent, IonPage, IonFooter, IonButton } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import useState from '@/services/state';
import TreeChart from '@/views/components/grid/TreeChart.vue';
import RouterWrapperComponent from '@/views/components/RouterWrapperComponent.vue';
import { GridInterface } from '@/services/grid';
import useServices from '@/services/services';

export default defineComponent({
    name: 'GridComponent',
    components: {
        IonContent,
        IonPage,
        TreeChart,
        RouterWrapperComponent,
        IonFooter,
        IonButton,
    },
    setup(): object {
        const { getProject, getGrid, setGrid } = useState();
        const { gridService } = useServices();
        const nodesToggled = ref<GridInterface[]>([]);

        async function onSubmit() {
            await gridService.update(getProject.value.id, getGrid.value);
        }

        async function resetGrid() {
            setGrid(await gridService.get(getProject.value.id));
        }

        async function handleNodesToggledEvent(changedNodes: GridInterface[]) {
            nodesToggled.value = changedNodes;
        }

        return { getGrid, onSubmit, resetGrid, handleNodesToggledEvent };
    },
});
