export enum Markers {
    NETTSTASJON = "https://maps.google.com/mapfiles/kml/paddle/ylw-circle.png",
    FORDELINGSTRANSFORMATOR = "https://maps.google.com/mapfiles/kml/paddle/wht-circle.png",
    "3-VIKLINGS FORDELINGSTRAFO" = "https://maps.google.com/mapfiles/kml/paddle/wht-circle.png",
    TOVIKLINGSTRANSFORMATOR = "https://maps.google.com/mapfiles/kml/paddle/wht-circle.png",
    TREVIKLINGSTRANSFOMATOR = "https://maps.google.com/mapfiles/kml/paddle/wht-circle.png",
    ABONNENT = "https://maps.google.com/mapfiles/kml/paddle/purple-circle.png",
    SELECTED = "https://maps.google.com/mapfiles/kml/paddle/grn-circle.png",
    WARNING = "https://maps.google.com/mapfiles/kml/paddle/red-circle.png",
    MISSING = "https://maps.google.com/mapfiles/kml/shapes/caution.png",
}

export interface MapItemInterface {
    id: number;
    enabled: boolean;
    objectId?: number;
    parentId?: number;
    type: string;
    componentName?: string;
    x: number;
    y: number;
    labelText: string;
    voltageOutOfRange?: boolean;
    icon: Markers;
    children?: MapItemInterface[];
}

export interface MapMarkerInterface {
    projectId?: number;
    objectId?: number;
    componentName: string;
    iconURL: string;
    selected?: boolean;
}
