
import { defineComponent, ref, watch, toRefs } from "vue";
import useState from "../../../services/state";
import { useRoute } from "vue-router";
import { IonPage, IonRouterOutlet, IonContent } from "@ionic/vue";
import BannerComponent from "../../components/BannerComponent.vue";
import RouterWrapperComponent from "@/views/components/RouterWrapperComponent.vue";
import TabComponent from "@/views/components/TabComponent.vue";
import useServices from "@/services/services";
import { TransformerMetadataInterface } from "@/services/metadata";

export default defineComponent({
  name: "TrafoDashboard",
  props: {
    objectId: {
      type: String,
      required: true,
    },
  },
  components: {
    IonPage,
    IonRouterOutlet,
    IonContent,
    RouterWrapperComponent,
    TabComponent,
  },
  setup(props): object {
    const {
      getTransformer,
      setTransformer,
      setTransformerMetadata,
    } = useState();
    const { netbasMetaService, metadataService } = useServices();
    const { objectId } = toRefs(props);
    const route = useRoute();
    const metadata = ref<TransformerMetadataInterface>(
      {} as TransformerMetadataInterface
    );
    const tabs = ref({
      tabs: [
        { name: "overview", path: "overview" },
        { name: "metadata", path: "metadata" },
      ],
    });

    async function fetchData() {
      setTransformer(
        await netbasMetaService.getTransformer(parseInt(objectId.value))
      );
      setTransformerMetadata(
        await metadataService.getTransformerMetadata(parseInt(objectId.value))
      );
    }

    watch(
      objectId,
      () => {
        fetchData();
      },
      { immediate: true }
    );

    return { getTransformer, tabs, metadata };
  },
});
