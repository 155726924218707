
import { defineComponent } from 'vue';
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/vue';
import useState from '@/services/state';
import GrafanaComponent from '@/views/components/grafana/GrafanaComponent.vue';
import GrafanaTimeframeComponent from '@/views/components/grafana/GrafanaTimeframeComponent.vue';

export default defineComponent({
    name: 'SubscriberOverviewComponent',
    components: {
        IonContent,
        IonPage,
        IonGrid,
        IonRow,
        IonCol,
        GrafanaComponent,
        GrafanaTimeframeComponent,
    },
    setup(): object {
        const { getSubscriber } = useState();
        return { getSubscriber };
    },
});
