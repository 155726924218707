
import { defineComponent, ref, watch } from 'vue';
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/vue';
import GoogleMapComponent from '@/views/components/GoogleMapComponent.vue';
import { MapItemHelper } from '@/helpers/MapItemHelper';
import useState from '@/services/state';
import { MapItemInterface } from '@/models/map';
import RouterWrapperComponent from '@/views/components/RouterWrapperComponent.vue';

export default defineComponent({
    name: 'SubstationMapComponent',
    components: {
        GoogleMapComponent,
        IonContent,
        IonPage,
        IonGrid,
        IonRow,
        IonCol,
        RouterWrapperComponent,
    },
    setup(): object {
        const mapData = ref<MapItemInterface[]>([]);
        const { getGrid } = useState();

        watch(
            getGrid,
            () => {
                setTimeout(() => {
                    mapData.value = MapItemHelper.gridToMapItem([getGrid.value]);
                }, 200);
            },
            { immediate: true }
        );

        return { mapData };
    },
});
