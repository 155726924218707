import { GridInterface } from "./grid";
import { MeterVoltageInterface } from "./netbasMeta";

export default class Helpers {
    static formatDate(date: string): string {
        const options: any = { year: '2-digit', month: '2-digit', day: 'numeric', hour: 'numeric', minute: 'numeric' };

        return new Date(date).toLocaleString('nb-NO', options);
    }

    static convertToKWH(q: number): number {
        return q * 1000;
    }

    static getPercentage(total: number, quantity: number) {
        if (quantity == 0) return 0.0;

        let percent = (quantity / total) * 100;

        if (percent < 1) return 1;

        return percent;
    }

    static voltageOutOfRange(voltage: number | undefined): boolean {
        if(!voltage) return false;

        let nominalVolt = 230
        let threshHold = 0.1;

        if (voltage < (nominalVolt * (1 - threshHold))) return true;
        if (voltage > (nominalVolt * (1 + threshHold))) return true;

        return false;
    }

    static addVoltagesToGrid(parent: GridInterface, meters: Array<MeterVoltageInterface>): GridInterface {

        if (parent.children) parent.children.forEach((child) => {
            this.addVoltagesToGrid(child, meters);
        });

        meters.forEach((meter) => {
            if (meter.id === parent.meterId) {
                parent.predictedVoltages = {
                    min: meter.min,
                    max: meter.max
                }
            }
        });

        return parent;
    }

    static GridToVoltageMetersArray(parent: GridInterface, data: Array<MeterVoltageInterface> = []): Array<MeterVoltageInterface> {
        let { children, meterId, predictedVoltages } = parent;
        if (meterId && predictedVoltages) data.push({
            id: meterId,
            min: predictedVoltages.min,
            max: predictedVoltages.max,
        });

        if (children) {
            children.forEach((child) => {
                this.GridToVoltageMetersArray(child, data);
            });
        }

        return data;

    }

    static convertToISOString(date: string): string {
        return new Date(date).toISOString();
    }
}
