
import { defineComponent, ref, onMounted, watch } from 'vue';
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonItem, IonLabel, IonInput, IonButton } from '@ionic/vue';
import useServices from '@/services/services';
import { useRoute } from 'vue-router';
import useState from '@/services/state';
import { TransformerMetadataInterface } from '@/services/metadata';
import MetaDataComponent from '../../MetaDataComponent.vue';
import { TransformerInterface } from '@/services/netbasMeta';

export default defineComponent({
    name: 'SubscriberMetadataComponent',
    components: {
        IonContent,
        IonPage,
        IonGrid,
        IonRow,
        IonCol,
        IonItem,
        IonLabel,
        IonInput,
        IonButton,
        MetaDataComponent,
    },
    props: {
        objectId: {
            type: String,
            required: true
        }
    },
    setup(): object {
        const { metadataService } = useServices();
        const { getTransformer, getTransformerMetadata } = useState();
        const route = useRoute();
        const transformerMetadata = ref<TransformerInterface | undefined>(undefined);
        const form = ref<TransformerMetadataInterface>({
            objectId: -1,
            meterId: '',
            scaleFactor: undefined,
        });

        onMounted(() => {
            watch([getTransformer, getTransformerMetadata], () => {
                transformerMetadata.value = getTransformer.value;
                form.value = getTransformerMetadata.value;
            }, {immediate: true});
        });

        async function onSubmit() {
            if (form.value.objectId >= 0) {
                await metadataService.updateTransformerMetadata(form.value);
                return;
            }

            form.value.objectId = parseInt(route.params.objectId.toString());

            await metadataService.createTransformerMetadata(form.value);
            return;
        }

        return { form, onSubmit, transformerMetadata };
    },
});
