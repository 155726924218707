
import { IonPage, IonButton, IonText } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import BannerComponent from "@/views/components/BannerComponent.vue";
import FlexProjectFormComponent from "@/views/components/flex/FlexProjectFormComponent.vue";
import { useRouter } from "vue-router";
import { FlexProjectFormInterface } from "@/services/project";
import useServices from "@/services/services";
import useState from "@/services/state";

export default defineComponent({
  name: "CreateProject",
  components: {
    IonPage,
    IonButton,
    FlexProjectFormComponent,
    IonText,
  },

  setup(): object {
    const router = useRouter();
    const { getProject, setProject, setGrid } = useState();
    const { projectService, gridService } = useServices();
    const message = ref({
      error: false,
      text: "",
    });

    function setMessage(error: boolean, msg: string) {
      message.value = { error: error, text: msg };

      setTimeout(() => {
        message.value.text = "";
      }, 5000);
    }

    async function onSubmit(form: FlexProjectFormInterface): Promise<void> {
      const { grid, ...project } = form;
      const nonReactiveGrid = Object.assign({}, grid);
      try {
        let newProject = await projectService.create(project);
        let newGrid = await gridService.post(
          newProject.id,
          nonReactiveGrid.componentName,
          nonReactiveGrid.transformerCircuit
        );

        setProject(newProject);
        setGrid(newGrid);

        router.replace(`/dashboards/projects/${getProject.value.id}`);
      } catch (err) {
        setMessage(true, err.message);
      }
    }

    return {
      onSubmit,
      message,
    };
  },
});
