
import { defineComponent, ref, watch } from 'vue';
import { IonPage, IonContent, IonGrid, IonRow, IonCol, modalController } from '@ionic/vue';
import GoogleMapComponent from '@/views/components/GoogleMapComponent.vue';
import EntitiesModal from '@/views/components/EntitiesModal.vue';
import { MapItemHelper } from '@/helpers/MapItemHelper';
import useState from '@/services/state';
import { MapItemInterface } from '@/models/map';
import RouterWrapperComponent from '../../RouterWrapperComponent.vue';

export default defineComponent({
    name: 'Entities',
    components: {
        GoogleMapComponent,
        IonContent,
        IonPage,
        IonGrid,
        IonRow,
        IonCol,
        RouterWrapperComponent
    },
    setup(): object {
        const mapData = ref<MapItemInterface[]>([]);
        const { getGrid } = useState();

        const handleClickedMarker = async function(marker: any) {
            if (marker.markersAtSameLocation.length) {
                const modal = await modalController.create({
                    component: EntitiesModal,
                    componentProps: {
                        dataSet: marker.markersAtSameLocation,
                    },
                });
                modal.present();
                let { data } = await modal.onDidDismiss();
                try {
                    marker.componentName = data.componentName;
                    marker.objectId = data.objectId;
                } catch (error) {
                    return;
                }
            }
        };

        watch(
            getGrid,
            () => {
                setTimeout(() => {
                    mapData.value = MapItemHelper.gridToMapItem([getGrid.value]);
                }, 200);
            },
            { immediate: true }
        );

        return { handleClickedMarker, mapData };
    },
});
