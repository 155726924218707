
import {
  IonCol,
  IonGrid,
  IonRow,
  IonContent,
  IonItem,
  IonLabel,
  IonInput,
  IonTextarea,
  IonButton,
  IonText,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { defineComponent, ref, onMounted, watch } from "vue";
import { NodeInterface, FlexProjectFormInterface } from "@/services/project";
import GoogleMapComponent from "@/views/components/GoogleMapComponent.vue";
import SearchComponent from "@/views/components/SearchComponent.vue";
import { useRoute } from "vue-router";
import useServices from "../../../services/services";
import { MapItemHelper } from "../../../helpers/MapItemHelper";
import useState from "../../../services/state";
import { MapMarkerInterface } from "../../../models/map";
import { GridNodeInterface, MarketInterface } from "@/services/nodes";

export default defineComponent({
  name: "FlexProjectFormComponent",
  components: {
    IonCol,
    IonGrid,
    IonRow,
    IonContent,
    IonItem,
    IonLabel,
    IonInput,
    IonTextarea,
    IonButton,
    GoogleMapComponent,
    SearchComponent,
    IonText,
    IonSelect,
    IonSelectOption,
  },
  emits: ["submit"],
  setup(props, { emit }): object {
    const { getProject, getGrid } = useState();
    const route = useRoute();
    const { nodesService } = useServices();
    const findRootNode = ref(false);
    const gridNodes = ref<Array<GridNodeInterface>>([]);
    const markets = ref<Array<MarketInterface>>([]);
    const markerSelected = ref<string>("");
    const form = ref<FlexProjectFormInterface>({
      id: -1,
      name: "",
      description: "",
      nodesGridNodeId: "",
      nodesMarketId: "",
      grid: {
        componentName: "",
        transformerCircuit: "",
      },
    });
    let filteredRootNodes = ref<NodeInterface[]>([]);

    const handleFilteredListChangedEvent = (rootNodes: NodeInterface[]) => {
      filteredRootNodes.value = rootNodes;
    };

    function handleSelectedRootNode(node: NodeInterface) {
      form.value.grid = {
        componentName: node.componentName,
        transformerCircuit: node.transformerCircuit,
      };

      findRootNode.value = false;
    }

    function handleClickedMarker(marker: MapMarkerInterface) {
      filteredRootNodes.value.forEach((node) => {
        if (parseInt(node.objectId) == marker.objectId)
          return handleSelectedRootNode(node);
      });
    }

    function resetForm(): void {
      form.value.id = route.params.id ? getProject.value.id : -1;
      form.value.name = route.params.id ? getProject.value.name : "";
      form.value.description = route.params.id
        ? getProject.value.description
        : "";
      form.value.nodesGridNodeId = route.params.id
        ? getProject.value.nodesGridNodeId
        : "";
      form.value.nodesMarketId = route.params.id
        ? getProject.value.nodesMarketId
        : "";
      form.value.grid.componentName = route.params.id
        ? getGrid.value.componentName
        : "";
      form.value.grid.transformerCircuit = route.params.id
        ? getGrid.value.transformerCircuit
        : "";
    }

    function onSubmit() {
      emit("submit", form.value);
    }

    onMounted(async () => {
      gridNodes.value = await nodesService.getGridNodes();
      markets.value = await nodesService.getMarkets();

      watch(
        [getProject, getGrid],
        () => {
          resetForm();
        },
        { immediate: true }
      );
    });

    return {
      form,
      gridNodes,
      markets,
      onSubmit,
      findRootNode,
      handleFilteredListChangedEvent,
      handleClickedMarker,
      handleSelectedRootNode,
      markerSelected,
      filteredRootNodes,
      MapItemHelper,
    };
  },
});
