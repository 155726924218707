
import { computed, defineComponent, toRefs, ref } from 'vue';
import { IonContent } from '@ionic/vue';

export default defineComponent({
    name: 'GrafanaComponent',
    props: {
        src: {
            type: String,
            required: true,
        },
        args: {
            type: Array,
            required: true,
        },
    },
    components: {
        IonContent,
    },
    setup(props): object {
        const baseURL = JSON.parse(process.env.VUE_APP_GRAFANA_PROXY).url;
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
        const darkTheme = ref<boolean>(prefersDark.matches);

        prefersDark.addListener((mediaQuery) => darkTheme.value = mediaQuery.matches);

        const { src, args } = toRefs(props);

        const graphSourceURL = computed(() => {
            return `${baseURL + src.value}?orgId=1&${args.value.join('&')}&kiosk${darkTheme.value ? '&theme=dark' : ''}`;
        });

        return {
            graphSourceURL,
        };
    },
});
