
import { defineComponent } from 'vue';
import { IonToggle, IonButton } from '@ionic/vue';
export default defineComponent({
    name: 'Nettstasjon',
    props: {
        dataSet: {
            type: Object,
            required: true,
        },
    },
    emits: ['toggle-enabled', 'extend', 'view'],
    components: {
        IonToggle,
        IonButton
    },
    methods: {
        handleToggle(event: any) {
            event.stopImmediatePropagation();
            event.stopPropagation();
            event.preventDefault();

            this.$emit('toggle-enabled', this.dataSet.flexId);
        },
        handleView() {
            this.$emit('view', this.dataSet);
        },
        handleClickEvent() {
            this.$router.push({
                name: 'substation',
                params: {
                    flexId: this.dataSet.flexId as number,
                    node: JSON.stringify(this.dataSet),
                },
            });
        },
    },
});
