import { AxiosInstance } from "axios";
import httpAxios from "./httpAxios";
import HttpAxios from "./httpAxios";
import useLoading from "./loading";

export interface GridNodeInterface {
    id: string;
    status: string;
    created: string;
    createdByUserID: string;
    lastModified: string;
    lastModifiedByUserID: string;
    name: string;
    comments: string;
    OperatedByOrganizationID: string;
    priceAreaID: string;
    mapPolygonID: string;
    gridAreaID: string;
    location: {
        longitude: number;
        latitude: number;
    };
    mpid: string;
}

export interface MarketInterface {
    id: string;
    status: string;
    created: string;
    createdByUserID: string;
    lastModified: string;
    lastModifiedByUserID: string;
    name: string;
    ownerOrganizationID: string;
    currencyID: string;
    minimumBlockSizeInSeconds: number;
    quantityType: string;
    quantityGranularity: number;
    minimumQuantity: number;
    timeZone: string;
    ispGateOpen: number;
    ispGateClose: number;
    ispGateClosedPolicy: string;
    allowOrderCreationWithoutBaseLine: boolean;
    tradeVisibility: string;
    allowedToBuy: string;
}

export interface TradeInterface {
    id: string;
    gridNodeId: string;
    marketId: string;
    periodFrom: string;
    periodTo: string;
    quantity: number;
    unitPrice: number;
    regulationType: string;
    status: string;
    createdByUserID: string;
    lastModifiedByUserID: string;
    side: string;
    created: string;
    lastModified: string;
    dealId: string;
    orderId: string;
    ownerOrganizationId: string;
    supplierOrganizationId: string;
    rebalancePrice: number;
    assetPortfolioId: string;
}
export interface BuyOrderInterface {
    id: string;
    gridNodeId: string;
    marketId: string;
    periodFrom: string;
    periodTo: string;
    validTo: string;
    quantity: number;
    minimumQuantity: number;
    renewableType: string;
    priceType: string;
    unitPrice: number;
    regulationType: string;
    fillType: string;
    status: string;
    completionType?: string;
    createdByUserID: string;
    lastModifiedByUserID: string;
    side: string;
    blockSizeInSeconds: number;
    maxBlocks: number;
    adjacentBlocks: number;
    restBlocks: number;
    created: string;
}

export interface BuyOrderFormInterface {
    id?: string;
    gridNodeId: string;
    marketId: string;
    periodFrom: string;
    periodTo: string;
    validTo: string;
    quantity: number | undefined;
    minimumQuantity: number;
    priceType: string;
    unitPrice: number | undefined;
    regulationType: string;
    fillType: string;
}
export interface PatchBuyOrderFormInterface {
    id?: string;
    periodFrom: string;
    periodTo: string;
    quantity: number | undefined;
    unitPrice: number | undefined;
    regulationType: string;
}
interface OrderItemInterface {
    quantity: number;
    unitPrice: number;
    numberOfOrders: number;
    discriminatorKeys: {
        displayValue: string;
        type: string;
        value: string;
    };
    restrictions: string;
}
interface OrderAggregatesTimeFrameInterface {
    sellItems?: Array<OrderItemInterface>;
    buyItems?: Array<OrderItemInterface>;
    periodFrom: string;
    periodTo: string;
}

export interface Entry {
    price: number;
    buyQuantity: number;
    sellQuantity: number;
}
export interface OrderBookInterface {
    entries: Array<Entry>;
    periodFrom: string;
    periodTo: string;
    totalBuyQuantity: number;
    totalSellQuantity: number;
}
export interface NodesServiceInterface {
    getGridNodes(): Promise<Array<GridNodeInterface>>;
    getGridNode(gridNodeId: string): Promise<GridNodeInterface>;
    getMarkets(): Promise<Array<MarketInterface>>;
    getMarket(marketId: string): Promise<MarketInterface>;
    getOrderBooks(marketId: string, regulationType: string, gridNodeId?: string, periodFrom?: string, periodTo?: string): Promise<Array<OrderBookInterface>>;
    getBuyOrders(gridNodeId: string, marketId: string): Promise<Array<BuyOrderInterface>>;
    getBuyOrder(buyOrderId: string): Promise<BuyOrderInterface>;
    getTrades(gridNodeId: string, marketId: string): Promise<Array<TradeInterface>>;
    getTrade(tradeId: string): Promise<TradeInterface>;
    createBuyOrder(createOrder: BuyOrderFormInterface): Promise<BuyOrderInterface>;
    updateBuyOrder(patchOrder: PatchBuyOrderFormInterface): Promise<void>;
    deleteBuyOrder(buyOrderId: string): Promise<void>;
}

function timeFramesToOrderBook(oa: Array<OrderAggregatesTimeFrameInterface>): Array<OrderBookInterface> {
    let oBooks: Array<OrderBookInterface> = [];
    oa.forEach((tf) => {
        let oBook: OrderBookInterface = {
            entries: [],
            periodFrom: tf.periodFrom,
            periodTo: tf.periodTo,
            totalBuyQuantity: 0,
            totalSellQuantity: 0
        };

        tf.buyItems?.forEach((item) => {
            const { quantity, unitPrice } = item;
            let entryFound = false;

            oBook.totalBuyQuantity += quantity;

            oBook.entries.forEach((entry) => {
                if (entry.price === unitPrice) {
                    entry.buyQuantity += quantity;
                    entryFound = true;
                }
            });

            if (!entryFound) {
                oBook.entries.push({
                    price: unitPrice,
                    buyQuantity: quantity,
                    sellQuantity: 0,
                });
            }
        });

        tf.sellItems?.forEach((item) => {
            const { quantity, unitPrice } = item;
            let entryFound = false;

            oBook.totalSellQuantity += quantity;

            oBook.entries.forEach((entry) => {
                if (entry.price === unitPrice) {
                    entry.sellQuantity += quantity;
                    entryFound = true;
                }
            });

            if (!entryFound) {
                oBook.entries.push({
                    price: unitPrice,
                    buyQuantity: 0,
                    sellQuantity: quantity,
                });
            }
        });
        oBooks.push(oBook);
    });

    return oBooks;
}

const { setLoadingStatus } = useLoading();
export class NodesService implements NodesServiceInterface {
    httpAxios: httpAxios;
    httpClient: AxiosInstance;
    API_KEY: string;

    constructor(private a: HttpAxios) {
        this.httpAxios = a;
        this.httpClient = this.httpAxios.getClient("nodes");
        this.API_KEY = this.httpAxios.getApiKey("nodes");
    }

    async getGridNodes(): Promise<Array<GridNodeInterface>> {
        let gridNodes: Array<GridNodeInterface> = [];

        try {
            setLoadingStatus(true);
            gridNodes = await (await this.httpClient.get(`/gridnodes?key=${this.API_KEY}`)).data.gridNodes;
        } catch (err) {
            let message = JSON.parse(err.response.data.message);
            throw new Error(message.Title);
        } finally {
            setLoadingStatus(false);
        }

        return gridNodes;
    }

    async getGridNode(gridNodeId: string): Promise<GridNodeInterface> {
        let gridNode = {} as GridNodeInterface;

        try {
            setLoadingStatus(true);
            gridNode = await (await this.httpClient.get(`/gridnode/${gridNodeId}?key=${this.API_KEY}`)).data.gridNode;
        } catch (err) {
            let message = JSON.parse(err.response.data.message);
            throw new Error(message.Title);
        } finally {
            setLoadingStatus(false);
        }

        return gridNode;
    }

    async getMarkets(): Promise<Array<MarketInterface>> {
        let markets: Array<MarketInterface> = [];

        try {
            setLoadingStatus(true);
            markets = await (await this.httpClient.get(`/markets?key=${this.API_KEY}`)).data.markets;
        } catch (err) {
            let message = JSON.parse(err.response.data.message);
            throw new Error(message.Title);
        } finally {
            setLoadingStatus(false);
        }

        return markets;
    }

    async getMarket(marketId: string): Promise<MarketInterface> {
        let market = {} as MarketInterface;

        try {
            setLoadingStatus(true);
            market = await (await this.httpClient.get(`/markets/${marketId}?key=${this.API_KEY}`)).data.market;
        } catch (err) {
            let message = JSON.parse(err.response.data.message);
            throw new Error(message.Title);
        } finally {
            setLoadingStatus(false);
        }

        return market;
    }

    async getTrades(gridNodeId = '', marketId = ''): Promise<Array<TradeInterface>> {
        let trades: Array<TradeInterface> = [];
        let searchParams = '';

        if (gridNodeId) searchParams += `&gridNodeId=${gridNodeId}`;
        if (marketId) searchParams += `&marketId=${marketId}`;

        try {
            trades = await (await this.httpClient.get(`/trades?key=${this.API_KEY}${searchParams}`)).data.trades;
        } catch (err) {
            let message = JSON.parse(err.response.data.message);
            throw new Error(message.Title);
        }

        return trades;
    }

    async getTrade(tradeId: string): Promise<TradeInterface> {
        let trade = {} as TradeInterface;

        try {
            setLoadingStatus(true);
            trade = await (await this.httpClient.get(`/trades/${tradeId}?key=${this.API_KEY}`)).data.trade;
        } catch (err) {
            let message = JSON.parse(err.response.data.message);
            throw new Error(message.Title);
        } finally {
            setLoadingStatus(false);
        }

        return trade;
    }

    async getOrderBooks(marketId: string, regulationType: string, gridNodeId: string, periodFrom: string, periodTo: string): Promise<Array<OrderBookInterface>> {
        let timeFrames: Array<OrderAggregatesTimeFrameInterface> = [];
        let params = `&marketId=${marketId}&regulationType=${regulationType}`;

        if (gridNodeId) params += `&gridNodeId=${gridNodeId}`;
        if (periodFrom) params += `&periodFrom=${periodFrom}`;
        if (periodTo) params += `&periodTo=${periodTo}`;

        try {
            timeFrames = await (await this.httpClient.get(`/orderAggregates?key=${this.API_KEY}${params}`)).data.timeFrames;
        } catch (err) {
            let message = JSON.parse(err.response.data.message);
            throw new Error(message.Title);
        }

        return timeFramesToOrderBook(timeFrames)
    }

    async getBuyOrders(gridNodeId = '', marketId = ''): Promise<Array<BuyOrderInterface>> {
        let orders: Array<BuyOrderInterface> = [];
        let searchParams = '';

        if (gridNodeId) searchParams += `&gridNodeId=${gridNodeId}`;
        if (marketId) searchParams += `&marketId=${marketId}`;

        try {
            orders = await (await this.httpClient.get(`/buyOrders?key=${this.API_KEY}${searchParams}`)).data.orders;
        } catch (err) {
            let message = JSON.parse(err.response.data.message);
            throw new Error(message.Title);
        }

        return orders;
    }

    async getBuyOrder(buyOrderId: string): Promise<BuyOrderInterface> {
        let order = {} as BuyOrderInterface;

        try {
            setLoadingStatus(true);
            order = await (await this.httpClient.get(`/buyOrders/${buyOrderId}?key=${this.API_KEY}`)).data.order;
        } catch (err) {
            let message = JSON.parse(err.response.data.message);
            throw new Error(message.Title);
        } finally {
            setLoadingStatus(false);
        }

        return order;
    }

    async createBuyOrder(createOrder: BuyOrderFormInterface): Promise<BuyOrderInterface> {
        let order = {} as BuyOrderInterface;

        try {
            setLoadingStatus(true);
            order = await (await this.httpClient.post(`/buyOrders?key=${this.API_KEY}`, { order: createOrder })).data.order;
        } catch (err) {
            let message = JSON.parse(err.response.data.message);
            throw new Error(message.Title);
        } finally {
            setLoadingStatus(false);
        }

        return order;
    }

    async updateBuyOrder(patchOrder: PatchBuyOrderFormInterface): Promise<void> {
        try {
            await this.httpClient.patch(`/buyOrders/${patchOrder.id}?key=${this.API_KEY}`, {order: patchOrder});
        } catch (err) {
            let message = JSON.parse(err.response.data.message);
            throw new Error(message.Title);
        }

        return;
    }

    async deleteBuyOrder(buyOrderId: string): Promise<void> {
        try {
            setLoadingStatus(true);
            await this.httpClient.delete(`/buyOrders/${buyOrderId}?key=${this.API_KEY}`);
        } catch (err) {
            let message = JSON.parse(err.response.data.message);
            throw new Error(message.Title);
        } finally {
            setLoadingStatus(false);
        }

        return;
    }
}
