
import { defineComponent } from 'vue';
import { IonToggle, IonButton } from '@ionic/vue';
import { GridInterface } from '@/services/grid';
export default defineComponent({
    name: 'Fordelingstransformator',
    props: {
        dataSet: {
            type: Object as () => GridInterface,
            required: true,
        },
    },
    emits: ['toggle-enabled', 'extend', 'view'],
    components: {
        IonToggle,
        IonButton,
    },
    methods: {
        handleToggle(event: any) {
            event.stopImmediatePropagation();
            event.stopPropagation();
            event.preventDefault();

            this.$emit('toggle-enabled', this.dataSet.flexId);
        },
        handleView() {
            this.$emit('view', this.dataSet);
        },
    },
});
