
import { IonContent, IonPage, IonIcon } from "@ionic/vue";
import { addCircle } from "ionicons/icons";
import { defineComponent, ref, watch } from "vue";
import useServices from "../../../services/services";
import { useRouter } from "vue-router";
import { ProjectsInterface } from "../../../services/project";
import { MapItemInterface } from "../../../models/map";

import { MapItemHelper } from "@/helpers/MapItemHelper";

import GoogleMapComponent from "@/views/components/GoogleMapComponent.vue";
import FooterComponent from "@/views/components/FooterComponent.vue";

const { projectService } = useServices();
const projects = ref<ProjectsInterface[]>([]);

export default defineComponent({
  name: "FlexDashboard",
  async ionViewWillEnter() {
    projects.value = await projectService.getAll();
  },
  components: {
    IonContent,
    IonPage,
    GoogleMapComponent,
    // IonIcon,
    FooterComponent,
  },
  setup(): object {
    const router = useRouter();
    const mapData = ref<MapItemInterface[]>([]);

    watch(
      projects,
      () => {
        if (projects.value)
          mapData.value = MapItemHelper.projectToMapItem(projects.value);
      },
      { immediate: true }
    );

    function handleClickedMarker(marker: any) {
      projects.value.forEach((project) => {
        if (project.id === marker.projectId) {
          router.push(`/dashboards/projects/${marker.projectId}`);
          return;
        }
      });

      return;
    }

    return { projects, addCircle, handleClickedMarker, mapData };
  },
});
