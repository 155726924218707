
import { defineComponent } from "vue";
import { IonContent } from "@ionic/vue";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "RouterWrapperComponent",
    components: {
        IonContent,
    },
    setup(): object {
        const router = useRouter();
        const entities = new Map<string, string>([
            ["NETTSTASJON", "substation"],
            ["FORDELINGSTRANSFORMATOR", "transformer"],
            ["ABONNENT", "subscriber"],
            ["3-VIKLINGS FORDELINGTRAFO", "transformer"],
            ["TOVIKLINGSTRANSFORMATOR", "transformer"],
            ["TREVIKLINGSTRANSFORMATOR", "transformer"],
        ]);

        function pushRoute(objectId: number, componentName: string) {
            router.push({
                path: `/dashboards/entities/${entities.get(componentName)}/${objectId}`,
            });
        }

        function pushTabRoute(tab: string) {
            router.push(tab)
        }

        return { pushRoute, pushTabRoute };
    },
});
