import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_tab_component = _resolveComponent("tab-component")!
  const _component_router_wrapper_component = _resolveComponent("router-wrapper-component")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_wrapper_component, null, {
            default: _withCtx(({ pushTabRoute }) => [
              _createVNode(_component_tab_component, {
                tabs: _ctx.tabs,
                onSelected: ($event: any) => (pushTabRoute($event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_content, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_router_outlet)
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["tabs", "onSelected"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}