
import { IonContent, IonPage, IonButton } from '@ionic/vue';
import { defineComponent, toRefs, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import useServices from '@/services/services';
import useState from '@/services/state';
import GrafanaComponent from '@/views/components/grafana/GrafanaComponent.vue';
import GrafanaTimeframeComponent from '@/views/components/grafana/GrafanaTimeframeComponent.vue';

export default defineComponent({
    name: 'TransformerComponent',
    props: {
        objectId: {
            type: String,
            required: true,
        },
    },
    components: {
        IonContent,
        GrafanaComponent,
        GrafanaTimeframeComponent,
        IonPage,
        IonButton
    },
    setup(props): object {
        const { netbasMetaService, metadataService } = useServices();
        const { objectId } = toRefs(props);
        const route = useRoute();
        const { setTransformer, getTransformer, setTransformerMetadata, getTransformerMetadata } = useState();
        const meterId = computed(() => getTransformerMetadata.value.meterId);
        const substation = route.path.toString().includes('substation');
        const trafoId = computed(() => {
            if(substation && typeof(route.query.objectId?.toString()) === 'string') {
                return parseInt(route.query.objectId?.toString());
            } else {
                return parseInt(objectId.value);
            }
        });

        watch(
            [trafoId, getTransformer],
            async () => {
                if (!getTransformer.value)
                    setTransformer(await netbasMetaService.getTransformer(trafoId.value));
                    setTransformerMetadata(await metadataService.getTransformerMetadata(trafoId.value));
            },
            { immediate: true }
        );

        return {
            meterId,
            trafoId
        };
    },
});
