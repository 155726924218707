
import { IonPage, IonButton, IonModal, IonGrid, IonRow, IonCol, IonText, IonTitle, IonLabel } from '@ionic/vue';
import { useRouter } from 'vue-router';
import { defineComponent, ref } from 'vue';
import { MapItemHelper } from '@/helpers/MapItemHelper';
import useState from '@/services/state';
import useServices from '@/services/services';
import { ProjectInterface, FlexProjectFormInterface } from '@/services/project';
import FlexProjectFormComponent from '@/views/components/flex/FlexProjectFormComponent.vue';

const project = ref<ProjectInterface>({} as ProjectInterface);
const { getProject, setProject, getGrid, setGrid } = useState();

export default defineComponent({
    name: 'EditProjectComponent',
    components: {
        IonPage,
        IonButton,
        IonModal,
        FlexProjectFormComponent,
        IonText,
        IonGrid,
        IonRow,
        IonCol,
        IonTitle,
        IonLabel,
    },
    ionViewWillEnter() {
        project.value = getProject.value;
    },
    setup(): object {
        const { projectService, gridService } = useServices();
        const router = useRouter();
        const message = ref({
            error: false,
            text: '',
        });
        const markerSelected = ref<string>('');
        const isOpenRef = ref(false);
        const setOpen = (state: boolean) => (isOpenRef.value = state);

        function setMessage(error: boolean, msg: string) {
            message.value = { error: error, text: msg };

            setTimeout(() => {
                message.value.text = '';
            }, 5000);
        }

        async function onSubmit(form: FlexProjectFormInterface) {
            let { grid, ...project } = form;
            let nonReactiveGrid = Object.assign({}, grid);

            try {
                setProject(await projectService.update(project));

                if (
                    getGrid.value.transformerCircuit != nonReactiveGrid.transformerCircuit ||
                    getGrid.value.componentName != nonReactiveGrid.componentName
                ) {
                    setGrid(
                        await gridService.post(
                            project.id,
                            nonReactiveGrid.componentName,
                            nonReactiveGrid.transformerCircuit
                        )
                    );
                }
                setMessage(false, 'Project updated successfully');
            } catch (err) {
                setMessage(true, err.message);
            }
        }

        async function onDelete() {
            await setOpen(false);

            try {
                await projectService.delete(project.value.id);
                router.replace('/dashboards/projects');
            } catch (err) {
                message.value = { error: true, text: err?.message };
            }
        }

        return {
            project,
            onSubmit,
            markerSelected,
            onDelete,
            isOpenRef,
            setOpen,
            MapItemHelper,
            message,
        };
    },
});
