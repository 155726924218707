import { AxiosInstance } from 'axios';
import HttpAxios from './httpAxios';
import httpAxios from "./httpAxios";
import useLoading from "./loading";
export interface GridInterface extends Object {
    flexId: number;
    objectId: number;
    componentName: string;
    projectId: number;
    transformerCircuit: string;
    location: string;
    meterId?: string;
    predictedVoltages?: {
        min: number;
        max: number;
    };
    enabled: boolean;
    x: number;
    y: number;
    parentId?: number;
    extend?: boolean;
    children?: GridInterface[];
}
export interface GridServiceInterface {
    get(projectId: number): Promise<GridInterface>;
    post(projectId: number, componentName: string, transformerCircuit: string): Promise<GridInterface>;
    update(projectId: number, grid: GridInterface): Promise<void>;
}

const { setLoadingStatus } = useLoading();
export class GridService implements GridServiceInterface {
    httpAxios: httpAxios;
    httpClient: AxiosInstance;
    API_KEY: string;

    constructor(private a: HttpAxios) {
        this.httpAxios = a;
        this.httpClient = this.httpAxios.getClient("flex");
        this.API_KEY = this.httpAxios.getApiKey("flex");
    }

    async get(projectId: number): Promise<GridInterface> {
        let grid: GridInterface = {} as GridInterface;

        try {
            setLoadingStatus(true);
            grid = await (await this.httpClient.get(`grid/${projectId}?key=${this.API_KEY}`)).data.grid;
            grid = this.toggleExtend(grid);
        } catch (err: any) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return grid;
    }

    async post(projectId: number, componentName: string, transformerCircuit: string): Promise<GridInterface> {
        let grid: GridInterface = {} as GridInterface;

        try {
            setLoadingStatus(true);
            grid = await (await this.httpClient.post(`grid/${projectId}?key=${this.API_KEY}`, { componentName: componentName, transformerCircuit: transformerCircuit })).data.grid;
            grid = this.toggleExtend(grid);
        } catch (err: any) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return grid;
    }

    async update(projectId: number, grid: GridInterface): Promise<void> {
        let data = this.GridToArray(grid);

        try {
            setLoadingStatus(true);
            await this.httpClient.put(`grid/${projectId}?key=${this.API_KEY}`, { nodes: data });
        } catch (err: any) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return;
    }

    toggleExtend(parent: GridInterface): GridInterface {
        if (parent.children) {
            parent.extend = true;
            parent.children.forEach((child) => {
                if (child.children) {
                    this.toggleExtend(child);
                } else {
                    child.enabled = child.enabled ? true : false;
                }
            });
        }
        parent.enabled = parent.enabled ? true : false;
        return parent;
    }

    GridToArray(parent: GridInterface, data: Array<GridInterface> = []): Array<GridInterface> {
        let { children, ...object } = parent;
        data.push(object);

        if (children) {
            children.forEach((child) => {
                this.GridToArray(child, data);
            });
        }

        return data;

    }
}
