import { computed, reactive } from 'vue';
import { AccountInfo} from "@azure/msal-browser";

enum Roles {
    User = "App.User",
    Admin = "App.Admin"
}

interface AccountInterface {
    account: AccountInfoExtended;
}

interface AccountInfoExtended extends AccountInfo {
    idTokenClaims: {
        roles: Array<Roles>;
    };
}

let state: AccountInterface = reactive({
    account: sessionStorage.account ? JSON.parse(sessionStorage.account) : {} as AccountInterface,
});

export default function useAccount() {
    const getAccount = computed(() => state.account);

    async function setAccount(account: any): Promise<void> {
        state.account = account;
        await sessionStorage.setItem('account', JSON.stringify(state.account));
    }

    async function removeAccount(): Promise<void> {
        await sessionStorage.removeItem('account');
        state.account = {} as AccountInfoExtended;
    }

    function getUsername(): string {
        return state.account.username;
    }

    function getRole(): Roles {
        return state.account.idTokenClaims?.roles[0];
    }

    function loggedIn(): boolean {
        return state.account.username !== undefined;
    }

    return {
        getAccount, setAccount, removeAccount, getUsername, getRole, loggedIn
    }
}
