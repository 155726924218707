import { AxiosInstance } from 'axios';
import HttpAxios from './httpAxios';
import httpAxios from "./httpAxios";
import useLoading from './loading';

export interface TransformerMetadataInterface {
    objectId: number;
    meterId: string;
    scaleFactor: number | undefined;
}
export interface SubscriberMetadataInterface {
    objectId: number;
    inverterId: string;
}
export interface MetadataServiceInterface {
    getTransformerMetadata(objectId: number): Promise<TransformerMetadataInterface>;
    getAllTransformersMetadata(): Promise<TransformerMetadataInterface[]>;
    createTransformerMetadata(metaData: TransformerMetadataInterface): Promise<void>;
    updateTransformerMetadata(metaData: TransformerMetadataInterface): Promise<void>;
    deleteTransformerMetadata(objectId: number): Promise<void>;

    getSubscriberMetadata(objectId: number): Promise<SubscriberMetadataInterface>;
    getAllSubscribersMetadata(): Promise<SubscriberMetadataInterface[]>;
    createSubscriberMetadata(metaData: SubscriberMetadataInterface): Promise<void>;
    updateSubscriberMetadata(metaData: SubscriberMetadataInterface): Promise<void>;
    deleteSubscriberMetadata(objectId: number): Promise<void>;
}

const { setLoadingStatus } = useLoading();

export class MetadataService implements MetadataServiceInterface {
    httpAxios: httpAxios;
    httpClient: AxiosInstance;
    API_KEY: string;

    constructor(private a: HttpAxios) {
        this.httpAxios = a;
        this.httpClient = this.httpAxios.getClient("flex");
        this.API_KEY = this.httpAxios.getApiKey("flex");
    }

    async getTransformerMetadata(objectId: number): Promise<TransformerMetadataInterface> {
        let metadata: TransformerMetadataInterface = {} as TransformerMetadataInterface;

        try {
            setLoadingStatus(true);
            metadata = await (await this.httpClient.get<any>(`/metadata/transformer/${objectId}?key=${this.API_KEY}`)).data.metadata;
        } catch (err) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return metadata;
    }

    async getAllTransformersMetadata(): Promise<TransformerMetadataInterface[]> {
        let metadata: TransformerMetadataInterface[] = [];

        try {
            setLoadingStatus(true);
            metadata = await (await this.httpClient.get<any>(`/metadata/transformer}?key=${this.API_KEY}`)).data.metadata;
        } catch (err) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return metadata;
    }

    async createTransformerMetadata(metadata: TransformerMetadataInterface): Promise<void> {
        try {
            setLoadingStatus(true);
            await this.httpClient.post<void>(`/metadata/transformer?key=${this.API_KEY}`, { metadata: metadata });
        } catch (err) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return;
    }

    async updateTransformerMetadata(metadata: TransformerMetadataInterface): Promise<void> {
        try {
            setLoadingStatus(true);
            await this.httpClient.put<void>(`/metadata/transformer/${metadata.objectId}?key=${this.API_KEY}`, { metadata: metadata });
        } catch (err) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return;
    }

    async deleteTransformerMetadata(objectId: number): Promise<void> {
        try {
            setLoadingStatus(true);
            await this.httpClient.delete<void>(`/metadata/transformer/${objectId}?key=${this.API_KEY}`);
        } catch (err) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return;
    }

    async getSubscriberMetadata(objectId: number): Promise<SubscriberMetadataInterface> {
        let metadata: SubscriberMetadataInterface = {} as SubscriberMetadataInterface;

        try {
            setLoadingStatus(true);
            metadata = await (await this.httpClient.get<any>(`/metadata/subscriber/${objectId}?key=${this.API_KEY}`)).data.metadata;
        } catch (err) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return metadata;
    }

    async getAllSubscribersMetadata(): Promise<SubscriberMetadataInterface[]> {
        let metadata: SubscriberMetadataInterface[] = [];

        try {
            setLoadingStatus(true);
            metadata = await (await this.httpClient.get<any>(`/metadata/subscriber}?key=${this.API_KEY}`)).data.metadata;
        } catch (err) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return metadata;
    }

    async createSubscriberMetadata(metadata: SubscriberMetadataInterface): Promise<void> {
        try {
            setLoadingStatus(true);
            await this.httpClient.post<void>(`/metadata/subscriber?key=${this.API_KEY}`, { metadata: metadata });
        } catch (err) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return;
    }

    async updateSubscriberMetadata(metadata: SubscriberMetadataInterface): Promise<void> {
        try {
            setLoadingStatus(true);
            await this.httpClient.put<void>(`/metadata/subscriber/${metadata.objectId}?key=${this.API_KEY}`, { metadata: metadata });
        } catch (err) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return;
    }

    async deleteSubscriberMetadata(objectId: number): Promise<void> {
        try {
            setLoadingStatus(true);
            await this.httpClient.delete<void>(`/metadata/subscriber/${objectId}?key=${this.API_KEY}`);
        } catch (err) {
            throw new Error(err.response.data.message);
        } finally {
            setLoadingStatus(false);
        }

        return;
    }
}
