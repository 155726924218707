
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner,
} from "@ionic/vue";
import { defineComponent, onMounted, onBeforeUnmount } from "vue";
import BuyOrderFormComponent from "./nodes/BuyOrderFormComponent.vue";
import ListOrdersComponent from "./nodes/ListOrdersComponent.vue";
import ListTradesComponent from "./nodes/ListTradesComponent.vue";
import GrafanaSelectComponent from "@/views/components/grafana/GafanaSelectComponent.vue";
import GrafanaComponent from "@/views/components/grafana/GrafanaComponent.vue";
import OrderBooksComponent from "./nodes/OrderBooksComponent.vue";
import eventBus from "@/services/eventBus";

export default defineComponent({
  name: "ProjectOverviewComponent",
  components: {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    BuyOrderFormComponent,
    ListOrdersComponent,
    ListTradesComponent,
    OrderBooksComponent,
    GrafanaSelectComponent,
    GrafanaComponent,
    IonSpinner,
  },
  setup(): object {
    let interval: number;

    onMounted(() => {
      interval = setInterval(() => {
        eventBus().emitter.emit("update");
        eventBus().emitter.emit("updateOrderBook");
      }, 1000 * 60);
    });

    onBeforeUnmount(() => clearInterval(interval));

    return {};
  },
});
