
import {
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButton,
    modalController,
    IonLabel,
    IonItem,
    IonList,
    IonAvatar,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
    name: "Modal",
    props: {
        dataSet: {
            type: Array,
            required: true,
        },
    },
    methods: {
        onSubmit(componentName: string, objectId: number) {
            modalController.dismiss({
                componentName: componentName,
                objectId: objectId,
            });
        },
    },
    components: {
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButton,
        IonLabel,
        IonItem,
        IonList,
        IonAvatar,
    },
});
