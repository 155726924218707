
import { defineComponent, watch, ref } from "vue";
import { chevronForwardOutline } from "ionicons/icons";
import { IonList, IonItem, IonLabel, IonContent, IonIcon } from "@ionic/vue";
import { NodeInterface } from "@/services/project";
import { SearchResultInterface } from "../../services/netbasMeta";
import useServices from "../../services/services";

import FooterComponent from "@/views/components/FooterComponent.vue";

const { netbasMetaService } = useServices();

export default defineComponent({
  name: "SearchComponent",
  props: {
    selected: {
      type: String,
      required: false,
      default: "",
    },
    typeAhead: {
      type: Boolean,
      required: false,
      default: false,
    },
    components: {
      type: String,
      required: true,
      default: "TRANSFORMER,SUBSTATION,SUBSCRIBER",
    },
  },
  emits: ["list-changed", "selected"],
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonContent,
    FooterComponent,
  },
  data: () => {
    return {
      mock: [
        {
          componentName: "FORDELINGSTRANSFORMATOR",
          location: "KVALAVIKVEGEN 56",
          objectId: "4488910",
          transformerCircuit: "61019T1",
        },
        {
          componentName: "FORDELINGSTRANSFORMATOR",
          location: "KVALAVIKVEGEN 56",
          objectId: "4488910",
          transformerCircuit: "61019T1",
        },
        {
          componentName: "FORDELINGSTRANSFORMATOR",
          location: "KVALAVIKVEGEN 56",
          objectId: "4488910",
          transformerCircuit: "61019T1",
        },
        {
          componentName: "FORDELINGSTRANSFORMATOR",
          location: "KVALAVIKVEGEN 56",
          objectId: "4488910",
          transformerCircuit: "61019T1",
        },
      ],
    };
  },
  setup(props, { emit }): object {
    const selectedItem = ref<SearchResultInterface>(
      {} as SearchResultInterface
    );

    let searchResult = ref<SearchResultInterface[]>([]);
    let searchString = ref("");

    function selectTopLevel(node: NodeInterface) {
      selectedItem.value = node;
      emit("selected", node);
    }

    const search = async function search() {
      searchResult.value = await netbasMetaService.search(
        searchString.value,
        props?.components
      );
      emit("list-changed", searchResult.value);
    };

    watch(
      () => searchString.value,
      async (searchString: string) => {
        if (searchString.length >= 4) {
          if (props.typeAhead) search();
        } else {
          searchResult.value = [];
        }
      }
    );

    watch(
      () => props.selected,
      (selected: string) => {
        searchResult.value.map((item) => {
          if (selected == item.objectId) {
            searchString.value = item.transformerCircuit;
          }
        });
      }
    );

    return {
      searchResult,
      searchString,
      selectedItem,
      selectTopLevel,
      search,
    };
  },
});
