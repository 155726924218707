import { AxiosInstance } from "axios";
import HttpAxios from "./httpAxios";
import httpAxios from "./httpAxios";

export interface Graphinterface {
    id: number;
    name: string;
    url: string;
    favourite?: boolean;
    projectId: number;
}

export interface PartialGraphinterface {
    name: string;
    url: string;
    favourite?: boolean;
}

export interface GraphsServiceInterface {
    getGraphs(projectId: number): Promise<Array<Graphinterface>>;
    getGraph(projectId: number, graphId: number): Promise<Graphinterface>;
    createGraph(projectId: number, graph: PartialGraphinterface): Promise<Graphinterface> ;
    updateGraph(projectId: number, graphId: number, graph: PartialGraphinterface): Promise<Graphinterface>;
    deleteGraph(projectId: number, graphId: number): Promise<void>;
}

export class GraphService implements GraphsServiceInterface {
    httpAxios: httpAxios;
    httpClient: AxiosInstance;
    API_KEY: string;

    constructor(private a: HttpAxios) {
        this.httpAxios = a;
        this.httpClient = this.httpAxios.getClient("flex");
        this.API_KEY = this.httpAxios.getApiKey("flex");
    }

    async getGraphs(projectId: number): Promise<Array<Graphinterface>> {
        try {
            return (await this.httpClient.get(`/projects/${projectId}/graphs?key=${this.API_KEY}`)).data.graphs;
        } catch(err: any) {
            throw new Error(err.response.data.message);
        }
    }

    async getGraph(projectId: number, graphId: number): Promise<Graphinterface> {
        try {
            return (await this.httpClient.get(`/projects/${projectId}/graphs/${graphId}?key=${this.API_KEY}`)).data.graph;
        } catch(err: any) {
            throw new Error(err.response.data.message);
        }
    }

    async createGraph(projectId: number, graph: PartialGraphinterface): Promise<Graphinterface> {
        try {
            return (await this.httpClient.post(`/projects/${projectId}/graphs?key=${this.API_KEY}`, {graph: graph})).data.graph;
        } catch(err: any) {
            throw new Error(err.response.data.message);
        }
    }

    async updateGraph(projectId: number, graphId: number, graph: PartialGraphinterface): Promise<Graphinterface> {
        try {
            return (await this.httpClient.put(`/projects/${projectId}/graphs/${graphId}?key=${this.API_KEY}`, {graph: graph})).data.graph;
        } catch(err: any) {
            throw new Error(err.response.data.message);
        }
    }

    async deleteGraph(projectId: number, graphId: number): Promise<void> {
        try {
            return (await this.httpClient.delete(`/projects/${projectId}/graphs/${graphId}?key=${this.API_KEY}`)).data.graphs;
        } catch(err: any) {
            throw new Error(err.response.data.message);
        }
    }
}
