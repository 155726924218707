import router from "@/router";
import * as msal from "@azure/msal-browser";
import useAccount from './account';
interface AzureAuthenticationInterface {
    login(state: string): Promise<void>;
    logout(): Promise<void>;
}

interface MSALConfigInterface {
    auth: {
        clientId: string;
        authority: string;
        redirectUri: string;
        postLogoutRedirectUri: string;
    };
}

const { getAccount, setAccount, removeAccount  } = useAccount();

export default class AzureAuthenticator implements AzureAuthenticationInterface {
    azureClient: msal.PublicClientApplication;
    msalConfig: MSALConfigInterface;

    constructor() {
        this.msalConfig = JSON.parse(process.env.VUE_APP_MSAL_CONFIG);
        this.azureClient = new msal.PublicClientApplication(this.msalConfig);
    }

    async login(state: string): Promise<void> {
        const loginRequest = {
            scopes: [],
            state: state
        };

        this.azureClient
                .handleRedirectPromise()
                .then(async(tokenResponse) => {
                    if (!tokenResponse) {
                        const accounts = this.azureClient.getAllAccounts();
                        if (accounts.length === 0) {
                            // No user signed in
                            await this.azureClient.loginRedirect(loginRequest);

                        }
                    } else {
                        setAccount(tokenResponse.account);
                        router.replace(tokenResponse.state ? tokenResponse.state : '/');
                    }

                    return '';
                })
                .catch((err) => {
                    // Handle error
                    throw new Error(err);
                });
    }

    async logout(): Promise<void> {
        let logoutRequest = {
            account: getAccount.value,
        }

        try {
            await removeAccount();
            await this.azureClient.logoutRedirect(logoutRequest);
        } catch (err: any) {
            throw new Error(err);
        }
    }
}
