
import { IonCol, IonGrid, IonRow, IonContent, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "PageNotFound",
  components: {
    IonCol,
    IonGrid,
    IonRow,
    IonContent,
    IonPage,
  },
});
