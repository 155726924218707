
import { defineComponent, ref, onMounted } from 'vue';
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonText } from '@ionic/vue';
import useState from '@/services/state';
import useServices from '@/services/services';
import { OrderBookInterface } from '@/services/nodes';
import eventBus from '@/services/eventBus';
import Helpers from '@/services/helpers';

const { nodesService } = useServices();
const { getProject, getForm } = useState();
const orderBooks = ref<Array<OrderBookInterface>>([]);

async function fetchData(): Promise<void> {
    orderBooks.value = await nodesService.getOrderBooks(
        getProject.value.nodesMarketId,
        getForm.value.regulationType,
        getProject.value.nodesGridNodeId,
        getForm.value.periodFrom,
        getForm.value.periodTo
    );
}

export default defineComponent({
    name: 'OrderBooksComponent',
    components: {
        IonCard,
        IonCardContent,
        IonCardHeader,
        IonCardSubtitle,
        IonText,
    },
    setup(): object {
        onMounted(() => {
            eventBus().emitter.on('updateOrderBook', () => fetchData());
        });

        return { Helpers, orderBooks, getProject };
    },
});
