
import { defineComponent } from "vue";
import { IonToggle } from "@ionic/vue";
export default defineComponent({
    name: "Toviklingstransformator",
    props: {
        dataSet: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonToggle,
    },
    methods: {
        handle(event: any) {
            event.stopImmediatePropagation();
            event.stopPropagation();
            event.preventDefault();

            this.$emit("toggle-enabled", this.dataSet.flexId);
        },
    },
});
