
import { computed, defineComponent, reactive, ref, toRefs } from 'vue';
import {
    IonDatetime,
    IonSelectOption,
    IonSelect,
    IonLabel,
    IonItem,
    IonCol,
    IonGrid,
    IonRow,
    IonContent,
} from '@ionic/vue';

export default defineComponent({
    name: 'GrafanaTimeframeComponent',
    components: {
        IonCol,
        IonGrid,
        IonRow,
        IonLabel,
        IonItem,
        IonSelect,
        IonSelectOption,
        IonDatetime,
        IonContent,
    },
    props: {
        initial: {
            type: String,
            required: false,
            default: 'from=now-6M&to=now&var-groupby=1d',
        },
    },
    setup(props): object {
        const { initial } = toRefs(props);
        const dates = reactive({
            toDate: new Date(Date.now()),
            fromDate: new Date(Date.now()),
        });
        const fromDateIso = computed(() => dates.fromDate.toISOString());
        const selected = ref(initial.value);
        const timeFrame = computed(() => {
            if (selected.value != 'calendar') return selected.value;

            dates.fromDate.setHours(0, 0, 0, 0);
            dates.toDate.setHours(23, 59, 59, 999);

            return `from=${dates.fromDate.getTime()}&to=${dates.toDate.getTime()}&var-groupby=1min`;
        });

        function handleDateChange(date: string) {
            dates.fromDate = new Date(Date.parse(date));
            dates.toDate = new Date(Date.parse(date));
        }

        return {
            selected,
            timeFrame,
            fromDateIso,
            handleDateChange,
        };
    },
});
