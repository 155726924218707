
import { defineComponent, ref, onMounted, watch, computed } from 'vue';
import {
    IonButton,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonModal,
    IonItem,
    IonLabel,
    IonInput,
    IonText,
    IonRadio,
    IonRadioGroup,
    IonPopover,
    IonList,
    IonChip,
    IonGrid,
    IonRow,
    IonCol,
} from '@ionic/vue';
import {
    alertCircleOutline,
    addOutline,
    trashOutline,
    starOutline,
    star,
    arrowDownCircle,
    arrowUpCircle,
    caretDownOutline,
} from 'ionicons/icons';
import { Graphinterface, PartialGraphinterface } from '@/services/graphs';
import useServices from '@/services/services';
import useState from '@/services/state';
import { MeterVoltageInterface } from '@/services/netbasMeta';
import Helpers from '@/services/helpers';

const { getProject, getGrid } = useState();
const defaultGraph = {
    id: 0,
    name: 'Default',
    url: 'p6Lm6D9Gz/volt?from=now-24h&to=now',
    projectId: getProject.value.id,
};
const { graphService } = useServices();
const graphs = ref<Array<Graphinterface>>([]);
const selectedGraph = ref<Graphinterface>({} as Graphinterface);
const meters = ref<Array<MeterVoltageInterface>>([]);
const highVoltage = ref<boolean>(true);
const selectedMeter = ref<MeterVoltageInterface | undefined>(undefined);
const form = ref<PartialGraphinterface>({
    name: '',
    url: '',
    favourite: false,
});

const event = ref();
const isOpenSelectMeterId = ref(false);
const setSelectMeterIdOpen = (state: boolean, e: any) => {
    event.value = e;
    isOpenSelectMeterId.value = state;
};

const isOpenRef = ref(false);
const setOpen = (state: boolean) => (isOpenRef.value = state);

function sortMeterVoltages(data: Array<MeterVoltageInterface>): Array<MeterVoltageInterface> {
    data.sort(function(a, b) {
        if (highVoltage.value === true) return b.max - a.max;

        return a.min - b.min;
    });

    return data;
}

function splitURL(URL: string): string {
    if (URL && URL.includes('/d/')) URL = URL.split('/d/')[1];

    return URL;
}

function selectGraph(newGraph: Graphinterface | null = null) {
    let g = newGraph ? newGraph : defaultGraph;

    if (!newGraph) {
        graphs.value.forEach((graph) => {
            if (graph.favourite) g = graph;
        });
    }

    selectedGraph.value = g;

    return;
}

async function updateGraph(g: Graphinterface) {
    try {
        let updated = await graphService.updateGraph(getProject.value.id, g.id, g);
        let index = graphs.value.findIndex((item) => item.id === updated.id);

        graphs.value.splice(index, 1, updated);
    } catch (err) {
        console.log(err);
    }
}

function favourizeGraph() {
    let index = graphs.value.findIndex((item) => item.favourite);
    let g: Graphinterface = {} as Graphinterface;

    if (index > 0) {
        g = graphs.value[index];
        g.favourite = false;
        updateGraph(g);
    }

    if (selectedGraph.value.id != g.id) {
        selectedGraph.value.favourite = selectedGraph.value.favourite ? false : true;
        updateGraph(selectedGraph.value);
    }
}

async function createGraph() {
    setOpen(false);
    try {
        form.value.url = splitURL(form.value.url);

        let newGraph: Graphinterface = await graphService.createGraph(getProject.value.id, form.value);

        graphs.value.push(newGraph);
        selectGraph(newGraph);
    } catch (err) {
        console.log(err);
    } finally {
        form.value.name = '';
        form.value.url = '';
    }
}

async function deleteGraph() {
    try {
        await graphService.deleteGraph(getProject.value.id, selectedGraph.value.id);
        let index = graphs.value.findIndex((item) => item.id === selectedGraph.value.id);

        graphs.value.splice(index, 1);

        selectGraph();
    } catch (err) {
        console.log(err);
    }
}

export default defineComponent({
    name: 'GrafanaSelectComponent',
    components: {
        IonButton,
        IonIcon,
        IonSelect,
        IonSelectOption,
        IonModal,
        IonItem,
        IonLabel,
        IonInput,
        IonText,
        IonRadio,
        IonRadioGroup,
        IonPopover,
        IonList,
        IonChip,
        IonGrid,
        IonRow,
        IonCol,
    },

    setup(): object {
        const graphURL = computed(() => {
            if (selectedGraph.value.url) return selectedGraph.value.url.split('?')[0];

            return '';
        });

        const args = computed(() => {
            let src = selectedGraph.value.url;
            let values: Array<string> = [];
            let params: string;

            if(src) {
                params = src.split('?')[1]
                if (params) values.push(...params.split('&'))
            }

            if (values) return [...values, `var-MeterId=${selectedMeter.value ? selectedMeter.value.id : '' }`];

            return values;
        });

        onMounted(() => {
            watch(
                [getGrid, getProject],
                async () => {
                    meters.value = Helpers.GridToVoltageMetersArray(getGrid.value);

                    try {
                        let g = await graphService.getGraphs(getProject.value.id);

                        graphs.value = g ? g : [];
                        graphs.value.push(defaultGraph);

                        selectGraph();
                    } catch (err) {
                        console.log(err);
                    }
                },
                { immediate: true }
            );

            watch(
                [() => meters.value, highVoltage],
                () => {
                    meters.value = sortMeterVoltages(meters.value);
                    selectedMeter.value = meters.value[0];
                },
                { immediate: true }
            );
        });

        return {
            Helpers,
            createGraph,
            deleteGraph,
            favourizeGraph,
            form,
            addOutline,
            trashOutline,
            graphs,
            selectedGraph,
            selectedMeter,
            highVoltage,
            meters,
            isOpenRef,
            setOpen,
            event,
            starOutline,
            star,
            graphURL,
            arrowUpCircle,
            arrowDownCircle,
            setSelectMeterIdOpen,
            isOpenSelectMeterId,
            alertCircleOutline,
            IonPopover,
            caretDownOutline,
            args
        };
    },
});
