
import { IonContent, IonPage, IonRouterOutlet } from "@ionic/vue";
import { defineComponent, ref, watch, toRefs } from "vue";
import useServices from "../../../services/services";
import BannerComponent from "../../components/BannerComponent.vue";
import TabComponent from "../../components/TabComponent.vue";
import useState from "../../../services/state";
import RouterWrapperComponent from "../../components/RouterWrapperComponent.vue";
import { useRoute } from "vue-router";
import { NetbasEntitiesTranslation } from "@/services/netbasMeta";

export default defineComponent({
  name: "SubscriberDasboard",
  components: {
    TabComponent,
    IonContent,
    IonPage,
    IonRouterOutlet,
    RouterWrapperComponent,
  },
  setup(): object {
    const route = useRoute();
    const { objectId } = toRefs(route.params);
    const { netbasMetaService } = useServices();
    const { setSubscriber, getSubscriber } = useState();

    const tabs = ref({
      tabs: [
        { name: "overview", path: "overview" },
        { name: "metadata", path: "metadata" },
      ],
    });

    watch(
      objectId,
      async () => {
        let sub = await netbasMetaService.getSubscriber(
          parseInt(route.params.objectId.toLocaleString())
        );
        let parents = await netbasMetaService.search(
          sub.transformerCircuit,
          "TRANSFORMER,SUBSTATION"
        );
        sub.parents = parents;

        setSubscriber(sub);

        parents.forEach((parent) => {
          let cn = Object(NetbasEntitiesTranslation)[parent.componentName];
          tabs.value.tabs.push({
            name: `Go to: ${parent.componentName}`,
            path: `/dashboards/entities/${cn}/${parent.objectId}`,
          });
        });
      },
      { immediate: true }
    );

    return {
      tabs,
      getSubscriber,
    };
  },
});
