
import { defineComponent, ref } from 'vue';
import { GridInterface } from '@/services/grid';
import { NetbasEntities } from '@/services/netbasMeta';

import Abonnent from '@/views/components/grid/entities/Abonnent.vue';
import Fordelingstransformator from '@/views/components/grid/entities/Fordelingstransformator.vue';
import Nettstasjon from '@/views/components/grid/entities/Nettstasjon.vue';
import Toviklingstransformator from '@/views/components/grid/entities/Toviklingstransformator.vue';
import TreviklingsFordelingstrafo from '@/views/components/grid/entities/TreviklingsFordelingstrafo.vue';
import Treviklingstransformator from '@/views/components/grid/entities/Treviklingstransformator.vue';
import UtgangOgKurs from '@/views/components/grid/entities/UtgangOgKurs.vue';

export default defineComponent({
    name: 'TreeChart',
    props: {
        dataSet: {
            type: Object as () => GridInterface,
            required: true,
        },
    },
    emits: ['nodes-toggled', 'toggle-enabled', 'view'],
    components: {
        Abonnent,
        Fordelingstransformator,
        Nettstasjon,
        Toviklingstransformator,
        TreviklingsFordelingstrafo,
        Treviklingstransformator,
        UtgangOgKurs,
    },
    methods: {
        toggleExtend: function(node: any) {
            node.extend = !node.extend;
        },
    },
    setup(props, { emit }): object {
        const changedNodes = ref<GridInterface[]>([]);
        const iterateTree = (node: GridInterface, toggleId: number, nodeFound = false) => {
            //flip the enabled prop on the node where the ID´s match.
            if (node.flexId == toggleId) {
                nodeFound = true;
                node.enabled = !node.enabled;

                changedNodes.value.push(node);
            }

            //Iterate through all childs until we find correct node.
            if (node.children) {
                node.children.forEach((child: GridInterface) => {
                    iterateTree(child, toggleId, nodeFound);

                    //toggle all parents of the node that was enabled
                    if (child.enabled && !nodeFound) {
                        if (!node.enabled) {
                            node.enabled = true;
                            changedNodes.value.push(node);
                        }
                    }

                    //disable all children of the node that was disabled
                    if (!node.enabled && nodeFound) {
                        if (child.enabled) {
                            child.enabled = false;
                            changedNodes.value.push(child);
                        }
                        if (node.children) {
                            node.children.forEach((child: GridInterface) => {
                                iterateTree(child, toggleId, nodeFound);
                            });
                        }
                    }
                });
            }

            return;
        };

        const wrapperFunction = (node: GridInterface, toggleId: number) => {
            changedNodes.value = [];

            iterateTree(node, toggleId);

            emit('nodes-toggled', changedNodes);
        };

        const toggleEnabled = function(id: number) {
            /* Emit bubbles up to the root node
               Only root contains data for the entire tree */
            if (!props.dataSet.parentId) {
                wrapperFunction(props.dataSet, id);
            } else {
                emit('toggle-enabled', id);
            }
        };

        return { toggleEnabled, NetbasEntities };
    },

});
