
import { defineComponent, onMounted, ref } from "vue";
import { IonCard, IonCardContent, IonText } from "@ionic/vue";
import useState from "@/services/state";
import useServices from "@/services/services";
import eventBus from "@/services/eventBus";
import { TradeInterface } from "@/services/nodes";
import Helpers from "@/services/helpers";

const { getProject } = useState();
const { nodesService } = useServices();
const trades = ref<Array<TradeInterface>>([]);

async function fetch() {
  trades.value = await nodesService.getTrades(
    getProject.value.nodesGridNodeId,
    getProject.value.nodesMarketId
  );
}

export default defineComponent({
  name: "ListAllTradesComponent",
  components: { IonCard, IonCardContent, IonText },
  setup(): object {
    onMounted(async () => {
      fetch();

      eventBus().emitter.on("update", () => fetch());
    });

    return { Helpers, trades, getProject };
  },
});
