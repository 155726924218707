import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-170c059a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["colspan"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TreeChart = _resolveComponent("TreeChart", true)!
  const _component_abonnent = _resolveComponent("abonnent")!

  return (_ctx.dataSet)
    ? (_openBlock(), _createElementBlock("table", _hoisted_1, [
        _createElementVNode("tr", null, [
          _createElementVNode("td", {
            colspan: Array.isArray(_ctx.dataSet.children) ? _ctx.dataSet.children.length * 2 : 1,
            class: _normalizeClass({
                    parentLevel: Array.isArray(_ctx.dataSet.children) && _ctx.dataSet.children.length,
                    extend: Array.isArray(_ctx.dataSet.children) && _ctx.dataSet.children.length && _ctx.dataSet.extend,
                })
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Object(_ctx.NetbasEntities)[_ctx.dataSet.componentName]), {
              dataSet: _ctx.dataSet,
              onExtend: _ctx.toggleExtend,
              onToggleEnabled: _ctx.toggleEnabled,
              onView: _cache[0] || (_cache[0] = ($event: any) => (this.$emit('view', $event)))
            }, null, 8, ["dataSet", "onExtend", "onToggleEnabled"]))
          ], 10, _hoisted_2)
        ]),
        (
                Array.isArray(_ctx.dataSet.children) && _ctx.dataSet.extend && _ctx.dataSet?.children[0]?.componentName !== 'ABONNENT'
            )
          ? (_openBlock(), _createElementBlock("tr", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataSet.children, (children, index) => {
                return (_openBlock(), _createElementBlock("td", {
                  key: index,
                  colspan: "2",
                  class: "childLevel"
                }, [
                  _createVNode(_component_TreeChart, {
                    dataSet: children,
                    onToggleEnabled: _ctx.toggleEnabled,
                    onView: _cache[1] || (_cache[1] = ($event: any) => (this.$emit('view', $event)))
                  }, null, 8, ["dataSet", "onToggleEnabled"])
                ]))
              }), 128))
            ]))
          : (
                Array.isArray(_ctx.dataSet.children) && _ctx.dataSet?.children[0]?.componentName == 'ABONNENT' && _ctx.dataSet.extend
            )
            ? (_openBlock(), _createElementBlock("tr", _hoisted_4, [
                _createElementVNode("td", null, [
                  _createVNode(_component_abonnent, {
                    dataSet: _ctx.dataSet,
                    onExtend: _ctx.toggleExtend,
                    onToggleEnabled: _ctx.toggleEnabled,
                    onView: _cache[2] || (_cache[2] = ($event: any) => (this.$emit('view', $event)))
                  }, null, 8, ["dataSet", "onExtend", "onToggleEnabled"])
                ])
              ]))
            : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}