import axios, { AxiosInstance } from 'axios';

interface ApiConfig {
    friendlyName: string;
    apiKey: string;
    baseURL: string;
}

class ApiRegistry {
    private httpClient: AxiosInstance;
    private apiKey: string;

    constructor(baseURL: string, apiKey: string) {
        this.apiKey = apiKey;
        this.httpClient = axios.create({
            baseURL: baseURL
        });
    }

    getClient(): AxiosInstance {
        return this.httpClient;
    }

    getKey(): string {
        return this.apiKey;
    }
}
export default class HttpAxios {
    private registry: Map<string, ApiRegistry>;

    constructor() {
        this.registry = new Map<string, ApiRegistry>();
        // Read api config from environment
        let apiConfig: ApiConfig[] = JSON.parse(process.env.VUE_APP_APIS);
        apiConfig.forEach(c => {
            this.registry.set(c.friendlyName, new ApiRegistry(c.baseURL, c.apiKey))
        })
    }

    getClient(friendlyName: string): AxiosInstance{
        const reg = this.registry.get(friendlyName);
        if (!reg) {
            throw new Error(`Service ${friendlyName} not initialized`);
        }
        return reg.getClient();
    }

    getApiKey(friendlyName: string): string {
        const reg = this.registry.get(friendlyName);
        if (!reg) {
            throw new Error(`Service ${friendlyName} not initialized`);
        }
        return reg.getKey();
    }
}


