import { computed, reactive } from 'vue';
import { ProjectInterface } from './project';
import { GridInterface } from './grid';
import { SubscriberInterface, TransformerInterface } from './netbasMeta';
import useServices from './services';
import { BuyOrderFormInterface } from './nodes';
import Helpers from './helpers';
import { TransformerMetadataInterface } from './metadata';

export interface FlexProjectInterface {
    project: ProjectInterface;
    grid: GridInterface;
    form: BuyOrderFormInterface;
}
interface EntitiesInterface {
    subscriber: SubscriberInterface;
    transformer: TransformerInterface;
    transformerMetadata: TransformerMetadataInterface;
}

interface StateInterface {
    flexProject: FlexProjectInterface;
    entities: EntitiesInterface;
}

const state: StateInterface = reactive({
    flexProject: {
        project: {} as ProjectInterface,
        grid: {} as GridInterface,
        form: {} as BuyOrderFormInterface,
    },
    entities: {
        subscriber: {} as SubscriberInterface,
        transformer: {} as TransformerInterface,
        transformerMetadata: {} as TransformerMetadataInterface
    }
});

const { netbasMetaService } = useServices();

export default function useState() {
    function setFlexProject(p: FlexProjectInterface): void {
        state.flexProject = p;
    }

    const getFlexProject = (computed(() => state.flexProject));

    async function setGrid(g: GridInterface): Promise<void> {
        let meterVoltages = await netbasMetaService.getMeterVoltages(g);
        g = Helpers.addVoltagesToGrid(g, meterVoltages)

        state.flexProject.grid = g;
    }

    const getGrid = (computed(() => state.flexProject.grid));

    function setProject(p: ProjectInterface): void {
        state.flexProject.project = p;
    }

    const getProject = (computed(() => state.flexProject.project));

    function setForm(f: BuyOrderFormInterface): void {
        state.flexProject.form = f;
    }

    const getForm = (computed(() => state.flexProject.form));

    function setTransformer(t: TransformerInterface): void {
        state.entities.transformer = t;
    }

    const getTransformer = (computed(() => state.entities.transformer));

    function setTransformerMetadata(tm: TransformerMetadataInterface): void {
        state.entities.transformerMetadata = tm;
    }

    const getTransformerMetadata = (computed(() => state.entities.transformerMetadata));

    function setSubscriber(s: SubscriberInterface): void {
        state.entities.subscriber = s;
    }

    const getSubscriber = (computed(() => state.entities.subscriber));

    return {
         setFlexProject, getFlexProject, getForm, setForm, setProject, getProject, setGrid, getGrid, setTransformer, getTransformer, setTransformerMetadata, getTransformerMetadata, setSubscriber, getSubscriber
    }
}
