
import { defineComponent, ref, onMounted } from 'vue';
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonItem, IonLabel, IonInput, IonButton } from '@ionic/vue';
import useServices from '@/services/services';
import { useRoute } from 'vue-router';
import { SubscriberMetadataInterface } from '@/services/metadata';
import MetaDataComponent from '../../MetaDataComponent.vue';
import { SubscriberInterface } from '@/services/netbasMeta';

export default defineComponent({
    name: 'SubscriberMetadataComponent',
    components: {
        IonContent,
        IonPage,
        IonGrid,
        IonRow,
        IonCol,
        IonItem,
        IonLabel,
        IonInput,
        IonButton,
        MetaDataComponent,
    },
    setup(): object {
        const { metadataService, netbasMetaService } = useServices();
        const route = useRoute();
        const subscriberMetadata = ref<SubscriberInterface | undefined>(undefined);
        const form = ref<SubscriberMetadataInterface>({
            objectId: -1,
            inverterId: '',
        });

        onMounted(async () => {
            subscriberMetadata.value = await netbasMetaService.getSubscriber(
                parseInt(route.params.objectId.toString())
            );
            form.value = await metadataService.getSubscriberMetadata(parseInt(route.params.objectId.toString()));
        });

        async function onSubmit() {
            if (form.value.objectId >= 0) {
                await metadataService.updateSubscriberMetadata(form.value);
                return;
            }

            form.value.objectId = parseInt(route.params.objectId.toString());

            await metadataService.createSubscriberMetadata(form.value);
            return;
        }

        return { form, onSubmit, subscriberMetadata };
    },
});
