<template>
  <div class="nn__navbar">
    <div class="nn__navbar-links">
      <div class="nn__navbar-links_logo">
        <img @click="$router.push('/home')" :src="fagneLogo" />
      </div>
      <div class="nn__navbar-links_container">
        <p
          v-bind:class="{
            'active-link': getRoute('/dashboards/entities'),
          }"
        >
          <a @click="$router.push('/dashboards/entities')"
            >Information Dashboard</a
          >
        </p>
        <p
          v-bind:class="{
            'active-link': getRoute('/dashboards/projects'),
          }"
        >
          <a @click="$router.push('/dashboards/projects')">Projects</a>
        </p>
        <!--<p>
          <a @click="$router.push('/dashboards/flex-tools')">Tools</a>
        </p>-->
      </div>
    </div>
    <div class="nn__navbar-sign">
      <p>
        <span>{{ getUsername() }}</span>
      </p>
      <p v-if="loggedIn()" @click="logout">Log out</p>
      <button v-if="!loggedIn()" @click="$router.push('/login')" type="button">
        Log in
      </button>
    </div>
    <div class="nn__navbar-menu">
      <div>
        <ion-icon
          size="large"
          @click="toggleMenu()"
          :icon="getIcon()"
        ></ion-icon>
        <div v-if="toggle" class="nn__navbar-menu_container">
          <p>
            <a @click="navigate('/dashboards/entities')"
              >Information Dashboard</a
            >
          </p>
          <p>
            <a @click="navigate('/dashboards/projects')">Projects</a>
          </p>
          <!--<p>
            <a @click="navigate('/dashboards/flex-tools')">Tools</a>
          </p>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script type="ts">
    import {
        IonIcon,
    } from '@ionic/vue';
    import { menuOutline, closeOutline, watch } from "ionicons/icons"
    import {
        defineComponent
    } from 'vue';
    import AzureAuthenticator from '../../services/azure-authentication';
    import useAccount from '../../services/account';

export default defineComponent({
    name: 'BannerComponent',
    components: {
        IonIcon
    },
    props: {},
    setup: () => {
        const { getUsername, loggedIn } = useAccount();
        const azure = new AzureAuthenticator();
        const logout = async() => {
            try {
                await azure.logout();
            } catch (err) {
                console.log(err);
            }
        }

        return { logout, getUsername, loggedIn }
    },
    watch: {
      $route() {
        this.$forceUpdate();
      }
    },
    data: () => {
        return {
            toggle: false,
            fagneLogo: 'https://fagne.no/wp-content/themes/fagne/images/logo.svg'
        }
    },
    methods: {
        getIcon() {
            return this.toggle ? closeOutline: menuOutline;
        },
        toggleMenu() {
            this.toggle = !this.toggle;
        },
        navigate(route) {
            this.$router.push(route);
            this.toggle = false;
        },
        getRoute(value) {
          return this.$router?.currentRoute?._value?.fullPath?.includes(value);
        }
    }
});
</script>

<style>
a {
  color: #fff !important;
  text-decoration: none;
}

.nn__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-primary);
  font-size: 14px;
  z-index: 1001;
  min-height: 4rem;
  height: 5rem;
  /* padding: 2rem 6rem; */
}

.nn__navbar-links {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.nn__navbar-links_logo {
  cursor: pointer;
  margin-right: 2rem;
  margin-left: 2rem;
}
.nn__navbar-links_container {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.nn__navbar-links_container p:hover {
}

.nn__navbar-links_container p,
.nn__navbar-sign p {
  display: flex;
  align-items: center;
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  min-width: 120px;
  justify-content: center;
  cursor: pointer;
}

.nn__navbar-links_container p > a {
  padding: 2rem 2rem;
  font-size: 14px;
}

.nn__navbar-sign p {
  font-weight: bolder;
  font-size: 14px;
}

.active-link,
.nn__navbar-links_container p:hover {
  background-color: var(--color-secondary);
}

.active-link *,
.nn__navbar-links_container p:hover * {
  color: black !important;
}

.nn__navbar-sign button {
  padding: 0.5rem 1rem;
  color: #fff;
  background: var(--color-secondary);
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}

.nn__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nn__navbar-menu {
  margin-left: 1rem;
  margin-right: 2rem;
  cursor: pointer;
  display: none;
  position: relative;
}

.nn__navbar-menu a {
  cursor: pointer;
  font-weight: bolder;
}

.nn__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  min-width: 210px;
  border-radius: 5px;
  z-index: 10000;

  text-align: end;
  background: var(--color-primary);
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.active {
  color: black;
  background-color: var(--color-secondary);
}

@media screen and (max-width: 1050px) {
  .nn__navbar-links_container {
    display: none;
  }

  .nn__navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .nn__navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .nn__navbar {
    padding: 2rem;
  }

  .nn__navbar-sign {
    display: none;
  }

  .nn__navbar-menu_container {
    top: 20px;
  }

  .nn__navbar-menu_container-links-sign {
    display: block;
  }
}
</style>
