
import { defineComponent, computed } from 'vue';
import useState from '@/services/state';
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/vue';
import GrafanaComponent from '@/views/components/grafana/GrafanaComponent.vue';
import GrafanaTimeframeComponent from '@/views/components/grafana/GrafanaTimeframeComponent.vue';

export default defineComponent({
    name: 'SubstationOverviewComponent',
    components: {
        IonContent,
        IonPage,
        IonGrid,
        IonRow,
        IonCol,
        GrafanaComponent,
        GrafanaTimeframeComponent,
    },
    setup(): object {
        const { getGrid } = useState();
        const circuit = computed(() => {
            return `${
                getGrid.value.children ? `var-circuit=${getGrid.value.children[0].transformerCircuit.slice(0, -1)}` : ''
            }`;
        });

        return { getGrid, circuit };
    },
});
