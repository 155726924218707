
import gmapsInit from "@/services/gmaps";
import {} from "googlemaps";
import { defineComponent, onMounted, ref, toRefs, watch } from "vue";
import { gridOutline, flagOutline } from "ionicons/icons";
import {
  MapItemInterface,
  Markers,
  MapMarkerInterface,
} from "../../models/map";
import EntitiesModal from "@/views/components/EntitiesModal.vue";
import { modalController } from "@ionic/vue";

async function selectFromLocation(
  markers: Array<MapMarkerInterface>
): Promise<MapMarkerInterface | undefined> {
  if (markers.length == 1) return markers[0];

  const modal = await modalController.create({
    component: EntitiesModal,
    componentProps: {
      dataSet: markers,
    },
  });
  modal.present();
  let { data } = await modal.onDidDismiss();
  try {
    return data;
  } catch (error) {
    return;
  }
}

export default defineComponent({
  name: "GoogleMapComponent",
  props: {
    dataSet: {
      type: Object as () => MapItemInterface[],
      required: true,
    },
    markers: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectable: {
      type: Boolean,
      required: false,
      default: false,
    },
    markSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
    mapType: {
      type: String,
      required: false,
      default: "hybrid",
    },
  },
  emits: ["marker-selected"],
  setup(props, context): object {
    const { dataSet } = toRefs(props);
    const maps = ref(null); // template ref.
    let google: any = window.google;
    // eslint-disable-next-line
    let map: google.maps.Map<Element>;
    // eslint-disable-next-line
    let bounds: google.maps.LatLngBounds;
    // eslint-disable-next-line
    const markerRegistry = new Map<string, google.maps.Marker[]>();

    function clearMarkers(): void {
      markerRegistry.forEach((markers) => {
        markers.forEach((marker) => {
          marker.setMap(null);
        });
      });
      markerRegistry.clear();
      return;
    }
    // eslint-disable-next-line
    function registerMarker(marker: google.maps.Marker): void {
      let position = marker.getPosition()?.toString();
      if (position) {
        let markersAtPosition = markerRegistry.has(position)
          ? markerRegistry.get(position)
          : [];
        if (markersAtPosition) {
          markersAtPosition.push(marker);
          markerRegistry.set(position, markersAtPosition);
        }
      }

      return;
    }
    // eslint-disable-next-line
    function addListener(marker: google.maps.Marker): google.maps.Marker {
      if (props.markSelected) {
        if (marker.get("selected")) {
          marker.setIcon({
            labelOrigin: new google.maps.Point(30, 64),
            url: Markers.SELECTED,
          });
        }
      }

      marker.addListener("click", async () => {
        let position = marker.getPosition()?.toString();

        markerRegistry.forEach((markers) => {
          markers.forEach((m) => {
            if (m.get("objectId") == marker.get("objectId")) {
              if (props.markSelected) {
                m.set("selected", true);
                m.setIcon({
                  labelOrigin: new google.maps.Point(30, 64),
                  url: Markers.SELECTED,
                });
              }
            } else {
              m.set("selected", false);
              m.setIcon({
                labelOrigin: new google.maps.Point(30, 64),
                url: m.get("iconURL"),
              });
            }
          });
        });

        let markersAtSelectedPosition: Array<MapMarkerInterface> = [];

        markerRegistry.get(position ? position : "")?.forEach((ma) => {
          markersAtSelectedPosition.push({
            objectId: ma.get("objectId"),
            componentName: ma.get("componentName"),
            iconURL: marker.get("iconURL"),
          });
        });

        let selected = await selectFromLocation(markersAtSelectedPosition);

        if (selected) context.emit("marker-selected", selected);
      });

      return marker;
    }
    // eslint-disable-next-line
    function setNewBounds(marker: google.maps.Marker): void {
      // eslint-disable-next-line
      bounds.extend(marker.getPosition() as google.maps.LatLng);
      map.setCenter(bounds.getCenter());
      map.fitBounds(bounds);
    }

    function drawMarkers(): void {
      markerRegistry.forEach((LatLng) => {
        let transformer = null;
        let substation = null;
        let itemToDraw = null;

        LatLng.forEach((marker, i) => {
          let name = marker.get("componentName") as string;

          if (name?.includes("NETT")) substation = i;
          if (name?.includes("TRAFO")) transformer = i;
        });

        itemToDraw = substation
          ? LatLng[substation]
          : LatLng[transformer ? transformer : 0];

        itemToDraw.setMap(map);

        if (itemToDraw.get("iconURL") != Markers.MISSING)
          setNewBounds(itemToDraw);

        return;
      });
    }

    function drawProjectMarkers(): void {
      markerRegistry.forEach((LatLng) => {
        LatLng.forEach((marker) => {
          marker.setMap(map);

          if (marker.get("iconURL") != Markers.MISSING) setNewBounds(marker);
        });
      });
    }

    function addMarkers(item: MapItemInterface): void {
      if (!item.enabled) return;

      let { children, ...parent } = item;
      if (children) {
        children.forEach((child) => {
          addMarkers(child);
        });
      }
      // eslint-disable-next-line
      let marker: google.maps.Marker = new google.maps.Marker({
        position: new google.maps.LatLng(parent.y, parent.x),
        label: {
          text: parent.labelText,
          color: "white",
          fontWeight: "bold",
          fontSize: "14px",
        },
        icon: {
          labelOrigin: new google.maps.Point(30, 64),
          url: parent.voltageOutOfRange ? Markers.WARNING : parent.icon,
        },
      });

      const markerProperties = {
        id: parent.id,
        objectId: parent.objectId,
        componentName: parent.componentName,
        iconURL: parent.voltageOutOfRange ? Markers.WARNING : parent.icon,
        selected: false,
      };

      marker.setValues(markerProperties);

      if (props.selectable) marker = addListener(marker);

      registerMarker(marker);
    }

    onMounted(async () => {
      try {
        google = await gmapsInit();
        map = new google.maps.Map(maps.value);
        map.setMapTypeId(props.mapType);
        bounds = new google.maps.LatLngBounds();
        map.setCenter(new google.maps.LatLng(59.301709, 4.887423));
        map.setZoom(3);
      } catch (error) {
        console.error(error);
      }

      watch(dataSet, () => {
        let type = "";
        clearMarkers();

        dataSet.value.forEach((item: MapItemInterface) => {
          type = item.type;
          if (item.id || item.objectId) addMarkers(item);
        });

        if (type === "project") drawProjectMarkers();
        if (type === "grid" || type === "node") drawMarkers();

        return;
      });
    });

    return { maps, gridOutline, flagOutline };
  },
});
