import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TreeChart = _resolveComponent("TreeChart")!
  const _component_router_wrapper_component = _resolveComponent("router-wrapper-component")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_wrapper_component, null, {
            default: _withCtx(({ pushRoute }) => [
              _createVNode(_component_TreeChart, {
                dataSet: _ctx.getGrid,
                onView: ($event: any) => (pushRoute($event.objectId, $event.componentName))
              }, null, 8, ["dataSet", "onView"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}