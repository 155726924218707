import { computed, reactive, watch } from 'vue';
import { loadingController } from "@ionic/vue";

interface StateInterface {
   loading: boolean;
}

let state: StateInterface = reactive({ loading: false });
let lc: HTMLIonLoadingElement;

watch(() => state.loading, async (loading) => {
   if(loading) {
      if (lc) await lc.dismiss();

      lc = await loadingController.create({
         spinner: "bubbles",
         message: "Please wait...",
      });

      await lc.present();

      setTimeout(() => {
         loadingController.dismiss();
      }, 3000);
      return;
   } else {
      if (lc) await lc.dismiss();
      return;
   }
});

export default function useLoading() {
   function setLoadingStatus(bool: boolean): void {
      state.loading = bool;
   }
   const serviceIsLoading = computed(() => state.loading);

   return { serviceIsLoading, setLoadingStatus }
}
