
import { watch, defineComponent, ref, onMounted } from 'vue';
import { IonItem, IonText, IonToggle, IonLabel, IonButton, IonInput, IonGrid, IonRow, IonCol } from '@ionic/vue';
import useState from '../../services/state';
import useServices from '../../services/services';
import useAccount from '../../services/account';
import { FlexProjectFormInterface } from '@/services/project';

export default defineComponent({
    name: 'MetaDataComponent',
    props: {
        data: {
            type: Object,
            required: true,
        },
        horizontal: {
            type: Boolean,
            required: false,
            default: false,
        },
        editable: {
            type: Boolean,
            required: true,
        },
    },
    components: {
        IonItem,
        IonText,
        IonLabel,
        IonToggle,
        IonButton,
        IonInput,
        IonGrid,
        IonRow,
        IonCol,
    },
    setup(props): object {
        const { getProject } = useState();
        const { projectService } = useServices();
        const { getRole } = useAccount();
        const toggleEdit = ref<boolean>(false);
        const renderedData = ref<object>({});
        const filter = ref<object>({});

        function addDataToRender() {
            renderedData.value = {};
            for (let key in props.data) {
                if (Object(filter.value)[key]?.toggle) {
                    Object(renderedData.value)[Object(filter.value)[key].friendlyName] = Object(props.data)[key];
                }
            }
        }

        function addFilterProperties(): object {
            interface FilterPropertyInterface extends Object {
                friendlyName: string;
                toggle: boolean;
            }

            let keys = {
                objectId: {} as FilterPropertyInterface,
                componentName: {} as FilterPropertyInterface,
                inspectionsetId: {} as FilterPropertyInterface,
                parentComponentId: {} as FilterPropertyInterface,
                meterStatus: {} as FilterPropertyInterface,
                geoMethod: {} as FilterPropertyInterface,
                installationNo: {} as FilterPropertyInterface,
                meterNumbere: {} as FilterPropertyInterface,
                address: {} as FilterPropertyInterface,
                postalAddress: {} as FilterPropertyInterface,
                billingAddress: {} as FilterPropertyInterface,
                billingZipCode: {} as FilterPropertyInterface,
                dateMeterConn: {} as FilterPropertyInterface,
                voltage: {} as FilterPropertyInterface,
                phase: {} as FilterPropertyInterface,
                fuseSize: {} as FilterPropertyInterface,
                tariff: {} as FilterPropertyInterface,
                endUserGroup: {} as FilterPropertyInterface,
                localAuthority: {} as FilterPropertyInterface,
                zone: {} as FilterPropertyInterface,
                Station: {} as FilterPropertyInterface,
                Feeder: {} as FilterPropertyInterface,
                transformerCircuit: {} as FilterPropertyInterface,
                ChangedDate: {} as FilterPropertyInterface,
                ChangedBy: {} as FilterPropertyInterface,
                Remark: {} as FilterPropertyInterface,
                measureMID: {} as FilterPropertyInterface,
                serviceNumber: {} as FilterPropertyInterface,
                energyConsumption: {} as FilterPropertyInterface,
                yearOfReference: {} as FilterPropertyInterface,
                MinShortCCurrent: {} as FilterPropertyInterface,
                maxShortCCurrent: {} as FilterPropertyInterface,
                mimActivePower: {} as FilterPropertyInterface,
                shaseVoltage: {} as FilterPropertyInterface,
                voltageDrop: {} as FilterPropertyInterface,
                location: {} as FilterPropertyInterface,
                typeDesignation: {} as FilterPropertyInterface,
                distSubstationName: {} as FilterPropertyInterface,
                componentAddress: {} as FilterPropertyInterface,
                substationType: {} as FilterPropertyInterface,
                manufacture: {} as FilterPropertyInterface,
                dateOfMounting: {} as FilterPropertyInterface,
                yearOfConstruction: {} as FilterPropertyInterface,
                yearOfManufacture: {} as FilterPropertyInterface,
                numberOfOperatingFeeders: {} as FilterPropertyInterface,
                numberOfTransformersInstalled: {} as FilterPropertyInterface,
                earthFaultMonitoring: {} as FilterPropertyInterface,
                protectedInstallation: {} as FilterPropertyInterface,
                globalEarth: {} as FilterPropertyInterface,
                access: {} as FilterPropertyInterface,
                key: {} as FilterPropertyInterface,
                owner: {} as FilterPropertyInterface,
                mStation: {} as FilterPropertyInterface,
                mFeeder: {} as FilterPropertyInterface,
                x: {} as FilterPropertyInterface,
                y: {} as FilterPropertyInterface,
            };

            Object.keys(keys).forEach((key) => {
                Object(keys)[key] = {
                    friendlyName: key,
                    toggle: true,
                };
            });

            return keys;
        }

        const resetFilter = (key: string) => {
            Object(filter.value)[key].friendlyName = key;
            Object(filter.value)[key].toggle = true;
        };

        const handleEdit = async () => {
            toggleEdit.value = !toggleEdit.value;

            if (!toggleEdit.value) {
                let p = getProject;
                p.value.metadataFilters = JSON.stringify(filter.value);

                await projectService.update(getProject.value as FlexProjectFormInterface);
            }
        };

        onMounted(() => {
            filter.value = getProject.value.metadataFilters ? JSON.parse(getProject.value.metadataFilters) : {};

            if (!Object.keys(filter.value).length) {
                filter.value = addFilterProperties();
            }

            addDataToRender();

            watch(filter.value, () => {
                addDataToRender();
            });
            watch(
                () => props.data,
                () => {
                    if (!Object.keys(filter.value).length) {
                        addFilterProperties();
                    }

                    addDataToRender();
                }
            );
        });

        return {
            getRole,
            filter,
            toggleEdit,
            renderedData,
            handleEdit,
            resetFilter,
        };
    },
});
