
import { defineComponent, ref, toRefs, watch } from 'vue';
import { useRoute } from 'vue-router';
import { IonContent, IonButton, IonCol, IonRow, IonGrid } from '@ionic/vue';

interface Tab {
    name: string;
    path: string;
}

interface TabInterface extends Object {
    tabs: Array<Tab>;
}

export default defineComponent({
    name: 'TabComponent',
    props: {
        tabs: {
            type: Object as () => TabInterface,
            required: true,
        },
    },
    emits: ['selected'],
    components: {
        IonContent,
        IonButton,
        IonCol,
        IonRow,
        IonGrid,
    },
    setup(props, { emit }): object {
        const tabRegistry = ref(new Map<string, boolean>([]));
        const pathRegistry = ref(new Map<string, string | undefined>([]));
        const { tabs } = toRefs(props);
        const route = useRoute();

        watch(
            [tabs, () => route.path],
            () => {
                tabRegistry.value.clear();
                pathRegistry.value.clear();

                tabs.value.tabs.forEach((tab) => {
                    tabRegistry.value.set(tab.name, route.path.search(tab.path) > 0 ? true : false);
                    pathRegistry.value.set(tab.name, tab.path);
                });
            },
            { immediate: true, deep: true }
        );

        function handleTabClick(event: any) {
            let key = event.currentTarget.id as string;
            let parts = pathRegistry.value.get(key)?.split('/');

            if (parts && parts.length <= 1) {
                for (let tab of tabRegistry.value.keys()) {
                    tabRegistry.value.set(tab, tab == key ? true : false);
                }
            }

            emit('selected', pathRegistry.value.get(key) ? pathRegistry.value.get(key) : key);
        }

        return { tabRegistry, handleTabClick };
    },
});
