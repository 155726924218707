
import {
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/vue";
import { defineComponent, ref, watch } from "vue";
import flatPickr from "vue-flatpickr-component";
import useState from "@/services/state";
import useServices from "@/services/services";
import { calendarClearOutline } from "ionicons/icons";
import "flatpickr/dist/flatpickr.css";
import {
  BuyOrderFormInterface,
  PatchBuyOrderFormInterface,
} from "@/services/nodes";
import eventBus from "@/services/eventBus";
import Helpers from "@/services/helpers";

const { getProject, setForm } = useState();

function newFromDate(): string {
  let fromDate = new Date();
  fromDate.setHours(fromDate.getHours() + 2);
  fromDate.setSeconds(0);
  fromDate.setMinutes(0);
  fromDate.setMilliseconds(0);

  return fromDate.toISOString();
}

function newToDate(fromDate: string): string {
  let date = new Date(fromDate);
  date.setHours(date.getHours() + 1);
  return date.toISOString();
}

function convertToFormValues(
  buyOrder: BuyOrderFormInterface
): BuyOrderFormInterface {
  if (buyOrder.quantity) buyOrder.quantity = buyOrder.quantity * 1000;

  buyOrder.periodFrom = Helpers.convertToISOString(buyOrder.periodFrom);
  buyOrder.periodTo = Helpers.convertToISOString(buyOrder.periodTo);

  return buyOrder;
}

function convertFromFormValues(
  form: BuyOrderFormInterface
): BuyOrderFormInterface {
  if (!form.gridNodeId) form.gridNodeId = getProject.value.nodesGridNodeId;
  if (!form.marketId) form.marketId = getProject.value.nodesMarketId;

  if (form.quantity) form.quantity = form.quantity * 0.001; //Convert KW/h into MW/h
  if (form.unitPrice) form.unitPrice = parseInt(form.unitPrice.toString());

  form.validTo = form.periodTo;

  return form;
}

function newFormData(): BuyOrderFormInterface {
  return {
    gridNodeId: "",
    marketId: "",
    periodFrom: newFromDate(),
    periodTo: newToDate(newFromDate()),
    validTo: "",
    quantity: undefined,
    minimumQuantity: 0,
    priceType: "Limit",
    unitPrice: undefined,
    regulationType: "Down",
    fillType: "Normal",
  };
}

export default defineComponent({
  name: "BuyOrderComponent",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonButton,
    flatPickr,
    IonText,
  },
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
    formData: {
      type: Object as () => BuyOrderFormInterface,
      required: false,
    },
  },
  emits: ["fetch", "cancel"],
  setup(props, { emit }): object {
    const { nodesService } = useServices();
    const form = ref(newFormData());
    const message = ref({
      error: false,
      text: "",
    });

    if (props.formData) {
      form.value = convertToFormValues({ ...props.formData });
    }

    const datePickerConfig = {
      enableTime: true,
      // eslint-disable-next-line
      time_24hr: true,
      altInput: true,
      altFormat: "j. F - Y H:i",
      defaultMin: 0,
      dateFormat: "Z",
      minuteIncrement: 0,
    };

    function handleNewFromDate() {
      if (props.edit) return;

      form.value.periodTo = newToDate(form.value.periodFrom);
      setForm(form.value);

      eventBus().emitter.emit("updateOrderBook");

      return;
    }

    function handleNewToDate() {
      if (props.edit) return;

      setForm(form.value);

      eventBus().emitter.emit("updateOrderBook");
      return;
    }

    if (!props.edit) {
      watch(
        () => form.value.regulationType,
        () => {
          setForm(form.value);
          eventBus().emitter.emit("updateOrderBook");
        },
        { immediate: true }
      );
    }

    function setMessage(error: boolean, msg: string) {
      message.value = { error: error, text: msg };

      setTimeout(() => {
        message.value.text = "";
      }, 5000);
    }

    async function createBuyOrder() {
      try {
        await nodesService.createBuyOrder(convertFromFormValues(form.value));

        form.value.quantity = undefined;
        form.value.unitPrice = undefined;

        eventBus().emitter.emit("update");
        eventBus().emitter.emit("updateOrderBook");

        setMessage(false, "Order succsessfully created");
      } catch (err) {
        setMessage(true, err.message);
      }
    }

    async function updateBuyOrder() {
      let o = convertFromFormValues(form.value);
      let patchOrder = {
        id: o.id,
        periodFrom: o.periodFrom,
        periodTo: o.periodTo,
        quantity: o.quantity,
        unitPrice: o.unitPrice,
        regulationType: o.regulationType,
      };

      try {
        await nodesService.updateBuyOrder(
          patchOrder as PatchBuyOrderFormInterface
        );

        eventBus().emitter.emit("update");
        eventBus().emitter.emit("updateOrderBook");

        emit("cancel");
      } catch (err) {
        setMessage(true, err.message);
      }
    }

    async function onSubmit() {
      props.edit ? updateBuyOrder() : createBuyOrder();
    }

    return {
      form,
      message,
      datePickerConfig,
      calendarClearOutline,
      onSubmit,
      handleNewToDate,
      handleNewFromDate,
    };
  },
});
